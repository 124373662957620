// const ETH_PAY = "0x87a22D7405f22a077faCFB8eb0A5D95C980a86AB";
const ETH_PAY = "0x361867F4c717361Dd26b6CFCDbF2088e94384A42";
// const TetherToken = "0x185bab30466bc30b34d73829a19e04124dca7bd0";
const TetherToken = "0xab63fbA4Cb48EBC47aE23d5C9Da250f7dfe6e895";

const BNB_PAY = "0xadfe7a6ae68be8e0606329172bd5bb604343b050";
const BTCBToken = "0xae245F5d554DBe21bC29cd185ecbe7f5B667150E";
const BUSDToken = "0x3871E49B0Aa475634Dd0d95A26f130bEA136DDaE";

// const MATIC_PAY = "0x5c9c316F51666d1c8AFC60584891B49D9e02F82e";
const MATIC_PAY = "0x54Da3ADb772e670eB18aB871806DA16F2b10df93";
const EscrowToken = "0x429cb9738AC5C0874804b9aCfe2ddA64d43A695a";



const NODE_URL_RPC = "https://data-seed-prebsc-1-s1.binance.org:8545/"
const MATIC_RPC = "https://rpc-mumbai.maticvigil.com/"
const CHAIN_ID_BSC = "97"
const ETH_CHAIN_ID = "3"

module.exports = {
    // URL: "http://localhost:4000",
    URL: "https://aip.escrowtrade.io",
    NODE_URL_RPC: NODE_URL_RPC,
    MATIC_RPC,
    CHAIN_ID_BSC: CHAIN_ID_BSC,
    ETH_CHAIN_ID: ETH_CHAIN_ID,
    ETH_PAY,
    TETHER: TetherToken,
    BNB_PAY,
    BTCBToken,
    BUSDToken,
    EscrowToken,
    MATIC_PAY
}