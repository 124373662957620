import { useState, useEffect } from "react";
import view from '../images/view.svg'
import loader from '../images/loader.gif'
import api from "../apis";
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';




const notify = (response, types) =>
    types(response, {
        position: "top-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });


const MyNewOffers = ({ darkMode, authData, PayFee }) => {
    const datalimit = 20;
    const dataskip = 0;

    const [load, setload] = useState(true)
    const [offers, setOffers] = useState([]);
    const [offersLength, setOffersLength] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});
    const [filter, setFilter] = useState("");

    const getoffersLength = async () => {
        try {
            const response = await api.getoffersLength();
            const number = parseInt(response.message)

            setOffersLength(number)
            console.log(response.message);
            console.log("offer length", offersLength);
        }
        catch (e) {
            console.log(e);
        }
    }

    const getoffers = async () => {
        try {

            const payload = {
                skip: offers.length
            }

            const response = await api.getoffers(payload)

            setOffers([...offers, ...response.message])

            console.log(response.message);
        }
        catch (e) {
            console.log(e);
        }
    }

    const getoffersafterupdate = async () => {
        try {
            const response = await api.getoffers()

            setOffers(response.message)
            console.log(response.message);
        }
        catch (e) {
            console.log(e);
        }
    }

    const getofferbystatus = async () => {
        try {

            if (filter == "reset") {

                setload(true)
                const response = await api.getoffersbystatus({ status: filter })
                setOffers(response.message)
                console.log(response.message);
                if (filter == "reset") setFilter("")
            }
            else {

                setload(false)
                const response = await api.getoffersbystatus({ status: filter })
                setOffers(response.message)
                console.log(response.message);
                if (filter == "reset") setFilter("")
            }

        }
        catch (e) {
            console.log(e);
        }
    }

    const updateofferstatus = async (id, status) => {
        try {
            const payload = {
                _id: id,
                status: status
            }
            const response = await api.updateofferstatus(payload)
            if (response.success) {
                getoffersafterupdate();
                return notify("Updated", toast.success)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    function truncateString(str) {
        if (str !== "" & str.length > 25) {
            return str.substring(0, 25) + "...";
        }
        return str;
    }

    const pages = offersLength / 10;
    Math.round(pages)

    function dateConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = date + ', ' + month + ' ' + year;
        return time;
    }


    useEffect(() => {
        getoffers()
        getoffersLength()
    }, [])



    return (
        <div className={darkMode ? 'tradeSec darkMode' : 'tradeSec'} style={{ padding: "50px auto" }} >
            {
                showLoader &&
                <div className='loader'>
                    <img src={loader} alt="loader" />
                </div>
            }

            <div className="container">
                <div className="tradeTable mt-0">
                    <h1 >All Offers</h1>

                    <div className="alltradesR1">
                        <div className="userSearchMain pb-10-lg">
                            <select name="" placeholder="Select Status" value={filter} onChange={(e) => setFilter(e.target.value)} >
                                <option value="reset"> {filter == "" ? "Filter By Status" : "Reset"} </option>
                                <option value="pending">Pending</option>
                                <option value="un-paid">Un-paid</option>
                                <option value="active">Active</option>
                                <option value="un-active">Un-active</option>
                                <option value="reject">Reject</option>
                            </select>
                            <button className="btn sBtn" onClick={() => getofferbystatus()}>Filter by Status</button>
                        </div>
                    </div>


                    <div className="table-responsive">
                        <table className="table mb-2">
                            <thead>
                                <tr>
                                    {/* <th scope="col" className='cursorP tbh'>UPDATE OFFER</th> */}
                                    <th scope="col">OFFER TITLE</th>
                                    <th scope="col">PRICE</th>
                                    <th scope="col">STATUS</th>
                                    <th scope="col" className='cursorP tbh'>EXPIRES ON</th>
                                    <th scope="col">LEFT STOCK</th>
                                    <th scope="col">PLAN</th>
                                    <th scope="col">ANALYTICS</th>
                                </tr>
                            </thead>
                            <tbody className='pb-2'>
                                {
                                    offers?.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                {/* <th scope="row">
                                                    <div className="alltradesR1">
                                                        <div className="userSearchMain p-0">
                                                            <select style={{ width: "auto", padding: "0px" }} onChange={(e) => updateofferstatus(item?._id, e.target.value)}>
                                                                <option selected={item?.status == "pending" ? true : false} value="pending">Pending</option>
                                                                <option selected={item?.status == "un-paid" ? true : false} value="un-paid">Un-paid</option>
                                                                <option selected={item?.status == "active" ? true : false} value="active">Active</option>
                                                                <option selected={item?.status == "un-active" ? true : false} value="un-active">Un-active</option>
                                                                <option selected={item?.status == "reject" ? true : false} value="reject">Reject</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </th> */}
                                                <td style={{ paddingLeft: "40px" }}>{truncateString(item?.title)}</td>
                                                <td className="text-center">${item.price}</td>
                                                {
                                                    Date.now() < parseInt(item?.expiry) ?
                                                        <td style={{ display: "flex", justifyContent: "center", marginTop: "7px" }}>
                                                            <div className={`status ${item?.status == "active" ? "accept" : item?.status == "pending" ? "pending" : "dispute"} `} >{item?.status}</div>
                                                        </td>
                                                        :
                                                        <td style={{ display: "flex", justifyContent: "center", marginTop: "7px" }}>
                                                            <div className={`status dispute`} >expired</div>
                                                        </td>
                                                }

                                                <td className="text-center">{dateConverter(item?.expiry)}</td>
                                                <td className="text-center">{item?.availableQuantity === null ? "NaN" : item?.availableQuantity}</td>
                                                <td className="text-center">{item?.planid?.title}</td>

                                                <td className="text-center">
                                                    <a href={`/admin-offers/${item?._id}`} className='btn viewBtn' ><img src={view} alt="icon" /> View</a>
                                                </td>
                                                {/* <td className="text-center">
                                                    <a href={`/update-offer/${item?._id}`} target="_blank" className='btn viewBtn' style={{ backgroundColor: "#F7931A" }}><img src={view} alt="" /> Edit</a>
                                                </td> */}
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                        load && (
                            <div className="paginationM">
                                <div className="loadmorediv">
                                    {offers.length < offersLength && (
                                        <button onClick={() => getoffers()} className="cards-btn m-5">Load More</button>
                                    )}
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>

            <ToastContainer style={{ width: "max-content" }} />
        </div>
    );
};




export default MyNewOffers;