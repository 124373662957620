import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap"
import "bootstrap/dist/js/bootstrap.bundle"

import { BrowserRouter, Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import { Navbar } from './component/navbar';
import { Overveiw } from './component/overveiw';
import { AllWallet } from './component/allWallet';
import { AllTrades } from './component/allTrades';
import { AllUsers } from './component/allUser';
import AdminOffer from "./component/AdminOffer";
import AllOffer from "./component/alloffer";
import AdminSetting from "./component/adminsettings";

import { Token } from './component/manage-token';
import Login from './component/login';
import Web3 from "web3";
import axios from "axios";
import { URL } from "./config"
import { useSelector, useDispatch } from "react-redux";
import { login, logout, networkno } from './redux/actions/actions';

import ETH_PAY_ABI from "./eth/ETH_PAY_ABI.json"
import TETHER_ABI from "./eth/TetherABI.json"
import BNB_PAY_ABI from "./eth/BNB_PAY_ABI.json"
import BTCB_ABI from "./eth/BTCB_ABI.json"
import MATIC_PAY_ABI from "./eth/MATIC_ABI.json"


// ------------------------------
import jwt from "jwt-decode"
import { TradeDetail } from './component/tradeDetail';
import { UserDetail } from './component/userDetail';
// ==============================

import io from "socket.io-client";
import MyNewOffers from './component/newAllOffers';


const ETH_PAY = "0x7a1BeE93181d5080Cc257747bf78Fbede0e7BdCD";
const TETHER = "0x94F7fd8A132cD0512f1B6D8A304019ACA886C425";
const BNB_PAY = "0x2DE8eB5738dcF818a677C3FFCa143b08Cb0952E5";
const BTCBToken = "0x9b522448D4A3D71E1bC5d8BCCbf4F900101b5902";
const MATIC_PAY = "0xc0f16EE5cD69cDADA2E13a605cD75eB187FC0F3b";

const socket = io.connect(`${URL}`, { autoConnect: true });


function App() {
  const token = sessionStorage.getItem("token")
  const authData = useSelector((state) => state.authReducer)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);
  const [web3, setWeb3] = useState([]);
  const [account, setAccount] = useState(null);
  const [isConnected, setisConnected] = useState(false);


  // notification setting
  const notify = (response, types) =>
    types(response, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const handleDark = (condition) => {
    setDarkMode(condition)

    if (darkMode) {
      document.body.style.backgroundColor = "#E5E5E5 "
      localStorage.setItem("escrowDashboardDarkmode", false)
      console.log(condition);
      console.log(document.body);
    } else {
      document.body.style.backgroundColor = "#141A28"
      localStorage.setItem("escrowDashboardDarkmode", true)
      console.log(condition);
    }
  }

  const connetedData = (account) => {
    setAccount(account)
    console.log(account);
    console.log(darkMode);
  }

  function setAuth(token) {
    if (token.length > 0) {
      const data = jwt(token)["user"][0];
      dispatch(login({
        id: data._id,
        isConnected: true,
        wallet: data.wallet,
        email: data.email,
        role: data.role,
        username: data.username,
        isVerified: data.isVerified,
        timestamp: data.timestamp,
        kycStatus: data.kycStatus,
        aboutYou: "",
        country: ""
      }))
    }
  }

  const logingHandler = async () => {
    let a1;
    console.log("authData from route", authData);

    if (authData == "" || authData?.isConnected == false) {
      if (window.ethereum) {
        const web33 = await new Web3(window.ethereum)
        setWeb3(web33);

        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        console.log("chainId", chainId);

        if (chainId !== "0x5" && chainId != "0x61" && chainId != "0x13881") {
          alert("Connect to Correct Network")
          dispatch(networkno(false))
        } else {
          a1 = await window.ethereum.enable()
            .then(async account1 => {
              console.log(account1);

              await axios.post(`${URL}/auth/sign-in`, {
                wallet: account1[0],
                password: "123456"
              })
                .then(res => {
                  console.log(res);
                  if (res.data.msg == 'User login' && res.data.token) {

                    if (res.data?.data[0]?.role == "admin") {
                      dispatch(login({
                        id: "",
                        isConnected: true,
                        wallet: account1[0],
                        email: "",
                        role: "",
                        username: "",
                        isVerified: "",
                        timestamp: "",
                        kycStatus: "not-verified",
                        aboutYou: "",
                        country: ""
                      }))

                      sessionStorage.setItem("token", res.data.token);
                      setAuth(res.data.token)
                      notify("Login successfull", toast.success)
                      navigate("/dashboard")

                    } else {
                      OnlyAdmin()
                    }
                  }
                  if (res.data.error) {
                    navigate("/")
                    alert(res.data.error)
                  }
                })
                .catch(err => {
                  console.log(err);
                })
              return account1
            })
            .catch((err) => {
              if (err.code === 4001) {
                alert("User rejected the request")

              } else if (err.code === -32602) {
                alert("Please connect your registered wallet address or reload page")

              } else {
                alert(err.message.split(":")[0])
              }
            });
        }

      } else {
        alert("Install Metamask Wallet to continue")
      }

    } else {
      // navigate("/")
    }

  }

  // onPage refresh
  const refreshHandler = async () => {
    let a1;
    if (window.location.pathname === "/") return

    if (authData === "" || authData?.isConnected === false) {
      if (window.ethereum) {
        const web33 = await new Web3(window.ethereum)
        setWeb3(web33);

        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        console.log("chainId", chainId);

        if (chainId !== "0x5" && chainId !== "0x61" && chainId !== "0x13881") {
          alert("Connect to Correct Network")
          dispatch(networkno(false))
          navigate("/")

        } else {

          a1 = await window.ethereum.enable()
            .then(async account1 => {
              console.log(account1);

              await axios.post(`${URL}/auth/sign-in`, {
                wallet: account1[0],
                password: "123456"
              })
                .then(res => {
                  console.log(res);
                  if (res.data.msg === 'User login' && res.data.token) {
                    if (res.data?.data[0]?.role === "admin") {
                      dispatch(login({
                        id: "",
                        isConnected: true,
                        wallet: account1[0],
                        email: "",
                        role: "",
                        username: "",
                        isVerified: "",
                        timestamp: "",
                        kycStatus: "not-verified",
                        aboutYou: "",
                        country: ""
                      }))
                      sessionStorage.setItem("token", res.data.token);
                      setAuth(res.data.token)

                    } else {
                      return OnlyAdmin()
                    }
                  }
                  if (res.data.error) {
                    navigate("/")
                    alert(res.data.error)
                  }
                })
                .catch(err => {
                  console.log(err);
                })
              return account1
            })
            .catch((err) => {
              if (err.code === 4001) {
                alert("User rejected the request")

              } else if (err.code === -32602) {
                alert("Please connect your registered wallet address or reload page")

              } else {
                alert(err.message.split(":")[0])
              }
            });
        }

      } else {
        alert("Install Metamask Wallet to continue")
      }

    } else {
      // navigate("/")
    }

  }

  // detect Metamask account change
  typeof window.ethereum != "undefined" && window.ethereum.on('accountsChanged', function (accounts) {
    // console.log('accountsChanges',accounts);
    dispatch(login({
      id: "",
      isConnected: false,
      wallet: "",
      email: "",
      role: "",
      username: "",
      isVerified: false,
      timestamp: "",
      kycStatus: "not-verified",
      aboutYou: "",
      country: ""
    }))
    sessionStorage.clear()
    navigate("/")
  });


  // ETH AREA
  async function handleDisputeETH(_buyer, _seller, winner, _tradeId, currency, winnerName, offChainID) {
    try {
      const config = {
        headers: { "authorization": `Bearer ${token}` }
      };
      return await axios.post(`${URL}/admin/resolveDispute-eth`, {
        offChainID: offChainID,
        buyer: _buyer,
        seller: _seller,
        tradeID: _tradeId,
        winner,
        currency,
        winnerName,
        timestamp: new Date().toUTCString(),
      }, config)
        .then(res => {
          if (res.data.isAdmin == false) {
            alert("Only admin can call this")
            return false
          }
          return res.data._res;
        })
        .catch(err => {
          return false
          console.log(err);
        })

    } catch (error) {
      console.log(error);
      notify("System error", toast.error);
      return false;
    }
  }

  // BNB AREA
  async function handleDisputeBNB(_buyer, _seller, winner, _tradeId, currency, winnerName, offChainID) {
    try {
      const config = {
        headers: { "authorization": `Bearer ${token}` }
      };
      return await axios.post(`${URL}/admin/resolveDispute-bnb`, {
        offChainID: offChainID,
        buyer: _buyer,
        seller: _seller,
        tradeID: _tradeId,
        winner,
        currency,
        winnerName,
        timestamp: new Date().toUTCString(),
      }, config)
        .then(res => {
          if (res.data.isAdmin == false) {
            alert("Only admin can call this")
            return false
          }
          return res.data._res


        })
        .catch(err => {
          return false
          console.log(err);
        })

    } catch (error) {
      console.log(error);
      notify("System error", toast.error);
      return false;
    }
  }

  // MATIC AREA
  async function handleDisputeMATIC(_buyer, _seller, winner, _tradeId, currency, winnerName, offChainID) {
    try {
      const config = {
        headers: { "authorization": `Bearer ${token}` }
      };
      return await axios.post(`${URL}/admin/resolveDispute-matic`, {
        offChainID: offChainID,
        buyer: _buyer,
        seller: _seller,
        tradeID: _tradeId,
        winner,
        currency,
        winnerName,
        timestamp: new Date().toUTCString(),
      }, config)
        .then(res => {
          console.log("App.js", res);
          if (res.data.isAdmin == false) {
            alert("Only admin can call this")
            return false
          }
          return res.data._res

        })
        .catch(err => {
          return false
          console.log(err);
        })

    } catch (error) {
      console.log(error);
      notify("System error", toast.error);
      return false;
    }
  }


  function Logout() {
    dispatch(logout({}))
    sessionStorage.clear()
    setisConnected(false)
    navigate("/")
    alert("You are logout")
  }

  useEffect(() => {
    if (token != null) {
      setAuth(token)
    }

  }, [])

  useEffect(() => {
    var checkDarkMode = localStorage.getItem('escrowDashboardDarkmode');
    console.log(checkDarkMode === "true");
    if (checkDarkMode === "true") {
      document.body.style.backgroundColor = "#141A28"
      setDarkMode(true)
      console.log(checkDarkMode);
    } else if (checkDarkMode === "false") {
      document.body.style.backgroundColor = "#E5E5E5 "
      setDarkMode(false)
      console.log(checkDarkMode);
    }

  }, [])

  useEffect(() => {
    return () => {
      refreshHandler()
    }
  }, [])

  function OnlyAdmin() {
    sessionStorage.clear()
    navigate("/")
    return notify("Only Owner can access this resource", toast.error)
  }



  return (
    <div className="App">
      <Navbar handleDark={handleDark} darkMode={darkMode} connetedData={connetedData} Logout={Logout} logingHandler={logingHandler} />

      <Routes>
        <Route exact path='/' element={token != null ? <Navigate to={"/dashboard"} /> : <Login darkMode={darkMode} logingHandler={logingHandler} />} />

        <Route exact path='/dashboard' element={token == null ? <Navigate to={"/"} /> : <Overveiw darkMode={darkMode} authData={authData} />} />

        <Route exact path='/allTrades' element={token == null ? <Navigate to={"/"} /> : window.location.pathname != "/dashboard" && <AllTrades darkMode={darkMode} authData={authData} />} />
        <Route exact path='/allUsers' element={token == null ? <Navigate to={"/"} /> : window.location.pathname != "/dashboard" && <AllUsers darkMode={darkMode} authData={authData} />} />
        <Route exact path='/setting' element={token == null ? <Navigate to={"/"} /> : window.location.pathname != "/dashboard" && <AllWallet darkMode={darkMode} authData={authData} />} />
        <Route exact path='/manage-token' element={token == null ? <Navigate to={"/"} /> : window.location.pathname != "/dashboard" && <Token darkMode={darkMode} authData={authData} />} />
        <Route exact path='/UserDetail/:id' element={token == null ? <Navigate to={"/"} /> : window.location.pathname != "/dashboard" && <UserDetail darkMode={darkMode} />} />
        <Route exact path='/tradeDetail/:id' element={token == null ? <Navigate to={"/"} /> : window.location.pathname != "/dashboard" && <TradeDetail socket={socket} darkMode={darkMode} authData={authData} handleDisputeETH={handleDisputeETH} handleDisputeBNB={handleDisputeBNB} handleDisputeMATIC={handleDisputeMATIC} />} />

        <Route exact path='*' element={token == null ? <Navigate to={"/"} /> : <Navigate to={"/dashboard"} />} />

        <Route exact path="/admin-offers/:id" element={token == null ? <Navigate to={"/"} /> : window.location.pathname != "/dashboard" && <AdminOffer darkMode={darkMode} authData={authData} />} />
        <Route exact path="/admin-all-offers" element={token == null ? <Navigate to={"/"} /> : window.location.pathname != "/dashboard" && <AllOffer darkMode={darkMode} authData={authData} />} />
        <Route exact path="/admin-settings" element={token == null ? <Navigate to={"/"} /> : window.location.pathname != "/dashboard" && <AdminSetting darkMode={darkMode} authData={authData} />} />


        <Route exact path="/all-offers" element={token == null ? <Navigate to={"/"} /> : window.location.pathname != "/dashboard" && <MyNewOffers darkMode={darkMode} authData={authData} />} />

      </Routes>

      <ToastContainer style={{ width: "max-content" }} />

      <br />
      <br />
      <br />
    </div>
  );
}



export default App;