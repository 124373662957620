const initialState = [];

const ChatList_REDUCER = (state = initialState, action) => {
    switch (action.type) {
        case "ChatList":
            return action.payload || state;
    
        default:
            return state;
    }
}

export default ChatList_REDUCER;