import React, { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive'
import api from "../apis";
import { ToastContainer, toast } from 'react-toastify';

import "../Market.css"
import loader from '../images/loader.gif'


const AdminSetting = ({ darkMode }) => {
    const [plan, setPlan] = useState("Standard")
    const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' })
    const [planData, setPlanData] = useState([])
    const [product, setProduct] = useState([])
    const [category, setCategory] = useState([])

    const [showLoader, setShowLoader] = useState(false);
    const [productTitle, setProductTitle] = useState({
        product1: "",
        id: "",
    })
    const [categoryTitle, setCategoryTitle] = useState({
        category: "",
        id: "",
    })

    const [productcreate, setProductcreate] = useState({
        title: "",
        type: "",
    })


    const [categorycreate, setCategorycreate] = useState({
        title: "",
        type: "",
    })

    const notify = (response, types) =>
        types(response, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    const offers = [
        {
            cateogry: "Example",
            offer: "3",
        },
        {
            cateogry: "Example",
            offer: "3",
        },
    ]
    useEffect(() => {
        getplansData()
        productType()
        Category()
    }, [])

    const getplansData = async () => {
        try {
            const response = await api.getplans()
            console.log("planresponse", response);
            setPlanData(response.message)
        }
        catch (e) {
            console.log(e);
        }
    }

    const handlePPsearchchange = (event) => {
        console.log(event.target.name)
        const i = event.target.name
        let data = planData;
        data[i].PPsearch = event.target.value;
        setPlanData(data)
        console.log("updatedData", data);
    }

    const handleOGchange = (event) => {
        console.log(event.target.name)
        const i = event.target.name
        let data = planData;

        data[i].OuterGlow = event.target.checked;

        setPlanData(data)

        console.log("updatedData", data);
    }

    const handleAdschange = (event) => {
        console.log(event.target.name)
        const i = event.target.name
        let data = planData;
        data[i].ads = event.target.value;
        setPlanData(data)
        console.log("updatedData", data);
    }

    const handlePricechange = (event) => {
        console.log(event.target.name)
        const i = event.target.name
        let data = planData;
        data[i].price = event.target.value;
        setPlanData(data)
        console.log("updatedData", data);
    }

    const productType = async () => {
        try {
            const response = await api.getproductType()
            console.log("productresponse", response);
            setProduct(response.message)
        }
        catch (e) {
            console.log(e);
        }
    }

    const Category = async () => {
        try {
            const response = await api.getcategories()
            console.log("categoryresponse", response);
            setCategory(response.message)
        }
        catch (e) {
            console.log(e);
        }
    }

    const createCategories = async () => {
        try {
            setShowLoader(true)
            const payload = {
                // limit: 100,
                title: categorycreate.title,
                type: categorycreate.type
            }

            const response = await api.createcategories(payload)
            Category()
            categorycreate({})
            setShowLoader(false)
        }
        catch (e) {
            console.log(e);
            setShowLoader(false)
        }
    }



    const handleP1Change = (event) => {
        setProductTitle({ ...productTitle, product1: event.target.value, id: event.target.name });
        console.log(event.target.value);
        console.log(event.target.name);
    };

    const handleCatChange = (event) => {
        setCategoryTitle({ ...categoryTitle, category: event.target.value, id: event.target.name });
        console.log(event.target.value);
        console.log(event.target.name);
    };

    const handlePtitleChange = (event) => {
        setProductcreate({ ...productcreate, title: event.target.value });

    };
    const handlePtypeChange = (event) => {
        setProductcreate({ ...productcreate, type: event.target.value });

    };


    const handlecategorytitleChange = (event) => {
        setCategorycreate({ ...categorycreate, title: event.target.value });

    };
    const handlecategorytypeChange = (event) => {
        setCategorycreate({ ...categorycreate, type: event.target.value });

    };


    const createPT = async () => {
        try {
            setShowLoader(true)
            const payload = {
                title: productcreate.title,
                type: productcreate.type
            }
            const response = await api.createproductType(payload)
            productType()
            setShowLoader(false)
        }

        catch (e) {
            console.log(e);
            setShowLoader(false)
        }
    }

    const productRename = async (item) => {
        try {
            setShowLoader(true)

            if (item === productTitle.id) {

                const payload =
                {
                    title: productTitle.product1,
                    _id: productTitle.id
                }
                const response = await api.updateproductType(payload)
            }
            else {
                alert("wrong button")
            }
            productType()

            setShowLoader(false)
        }
        catch (e) {
            console.log(e);
            setShowLoader(false)
        }
    }
    const productDelete = async (item, status) => {
        try {
            setShowLoader(true)
            if (status === 0) {

                const payload =
                {
                    status: 1,
                    _id: item
                }
                const response = await api.updateproductType(payload)
            }
            else {

                const payload =
                {
                    status: 0,
                    _id: item
                }
                const response = await api.updateproductType(payload)
            }
            productType()
            window.location.reload()

            setShowLoader(false)
        }
        catch (e) {
            console.log(e);
            setShowLoader(false)
        }
    }




    const categoryRename = async (item) => {
        try {
            setShowLoader(true)
            if (item === categoryTitle.id) {

                const payload =
                {
                    title: categoryTitle.category,
                    _id: categoryTitle.id
                }
                const response = await api.updatecategories(payload)
            }
            else {
                alert("wrong button")
            }
            Category()
            setShowLoader(false)
        }
        catch (e) {
            console.log(e);
            setShowLoader(false)
        }
    }
    const categoryDelete = async (item, status) => {
        try {
            setShowLoader(true)
            console.log("id", item);
            if (status == 0) {
                const payload =
                {
                    status: 1,
                    _id: item

                }
                const response = await api.updatecategories(payload)
            }
            else {
                const payload =
                {
                    status: 0,
                    _id: item

                }
                const response = await api.updatecategories(payload)
            }
            Category()
            window.location.reload()

            setShowLoader(false)
        }
        catch (e) {
            console.log(e);
            setShowLoader(false)
        }
    }



    const updateplan = async (i) => {
        try {
            setShowLoader(true)
            let data = planData

            console.log("data", data[i]);

            const response = await api.updateplans(data[i])
            if (response.success) {

                notify("Updated", toast.success)
                getplansData()
            }
            setShowLoader(false)
        }
        catch (e) {

            notify("System error", toast.error)
            console.log(e);
            setShowLoader(false)
        }
    }



    return (
        <div className={darkMode ? "darkMode settingPage" : "settingPage"}   >
            <div className=" container">

                <div className="row justify-content-center mb-5 ">
                    <div className="col-12 col-md-12 col-lg-6 mb-3">
                        <div className="option-card options-pricing h-100">
                            <h1>General</h1>
                            <div className="no-option-table">
                                <label>Offer creation fee</label>
                                <div className="userSearchMain pb-2">
                                    <div className="usd-input">
                                        <input type="" placeholder="0.00" />
                                        <p className="USD m-0 p-0">$</p>
                                    </div>
                                    <button className="btn sBtn">Submit</button>
                                </div>

                                <label>Renewal fee</label>
                                <div className="userSearchMain pb-2">
                                    <div className="usd-input">
                                        <input type="" placeholder="0.00" />
                                        <p className="USD m-0 p-0">$</p>
                                    </div>
                                    <button className="btn sBtn">Submit</button>
                                </div>

                                <label>Offer Duration Period</label>
                                <div className="userSearchMain pb-2">
                                    <div className="usd-input">
                                        <input type="" placeholder="30" />
                                        <p className="USD m-0 p-0">Days</p>
                                    </div>
                                    <button className="btn sBtn">Submit</button>
                                </div>

                            </div>
                            {/* )} */}
                        </div>
                    </div>

                    <div className="col-12 col-md-12 col-lg-6 mb-3">
                        <div className="requirements-card requirements h-100">
                            <h1>Categories</h1>
                            <div class="alltradesR1">
                                <div className="userSearchMain py-3">
                                    <input onChange={(e) => handlecategorytitleChange(e)} className="mb-2 mx-1" placeholder="Enter category title" type="text" defaultValue={categorycreate.title} />
                                    <input onChange={(e) => handlecategorytypeChange(e)} className="mb-2 mx-1" placeholder="Enter category type" type="text" defaultValue={categorycreate.type} />

                                    <button onClick={() => createCategories()} className="btn sBtn">Create</button>
                                </div>
                            </div>

                            <div className="table-responsive tradeTable">
                                <table className="table mb-2">
                                    <thead>
                                        <tr>
                                            <th scope="col" className='cursorP tbh'>Category</th>
                                            <th scope="col" className='cursorP tbh'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className='pb-2'>
                                        {
                                            category?.map((item) => {
                                                return (
                                                    <tr >
                                                        <td >
                                                            <input defaultValue={item?.title} name={item?._id} onChange={(e) => handleCatChange(e)} type="text" className="tableinputs" />
                                                        </td>
                                                        <td>
                                                            <div className="actionMain">
                                                                <botton onClick={() => categoryRename(item?._id)} className="renameBtn">Rename</botton>
                                                                <botton onClick={() => categoryDelete(item?._id, item?.status)} className="renameBtn del">
                                                                    {
                                                                        item?.status === 1 ? "Un-active" : "Active"
                                                                    }
                                                                </botton>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-12 col-lg-6 mb-3">
                        <div className="requirements-card requirements h-100">
                            <h1>Product Types</h1>
                            <div class="alltradesR1">
                                <div className="userSearchMain py-3">
                                    <input onChange={(e) => handlePtitleChange(e)} className="mb-2 mx-1" placeholder="Enter Product Title" type="text" defaultValue={productcreate.title} />
                                    <input onChange={(e) => handlePtypeChange(e)} className="mb-2 mx-1" placeholder="Enter Product Type" type="text" defaultValue={productcreate.type} />

                                    <button onClick={() => createPT()} className="btn sBtn">Create</button>
                                </div>
                            </div>

                            <div className="table-responsive tradeTable">
                                <table className="table mb-2">
                                    <thead>
                                        <tr>
                                            <th scope="col" className='cursorP tbh'>Category</th>
                                            <th scope="col" className='cursorP tbh'>Actions</th>

                                        </tr>
                                    </thead>
                                    <tbody className='pb-2'>
                                        {
                                            product?.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <th scope="row">
                                                            <input defaultValue={item?.title} name={item?._id} onChange={(e) => handleP1Change(e)} type="text" className="tableinputs" />

                                                        </th>

                                                        <td>
                                                            <div className="actionMain">
                                                                <botton onClick={() => productRename(item?._id)} className="renameBtn">Rename</botton>
                                                                <botton onClick={() => productDelete(item?._id, item?.status)} className="renameBtn del">

                                                                    {
                                                                        item?.status === 1 ? "Un-active" : "Active"
                                                                    }
                                                                </botton>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                </div>

                <div className="boostPlanMain tradeSec">
                    <h1>Boost Plans</h1>
                    <div className="btnRow">
                        <div className="row jc-fe ai-center">
                            <div className="col-lg col-12">
                            </div>

                            {
                                planData.length > 0 && planData.map((item) => (
                                    <div className="col-lg col-md-3 col-6 mb-2 mb-md-0">
                                        <button className={`btn1 ${(item?.title).toLowerCase() + "Btn"} planCard`} onClick={() => setPlan(item?.title)}>{item?.title}</button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>



                    {/* for large Screen */}
                    {isLargeScreen ?
                        <>
                            <div className="priceRow ">
                                <div className="row ai-center">
                                    <div className="col-lg">
                                        <div className="title">Price</div>
                                    </div>

                                    {
                                        planData.length > 0 && planData.map((item, i) => (
                                            <div className="col-lg">
                                                <div className="usd-input ">
                                                    <input type="" name={i} onChange={(e) => handlePricechange(e)} defaultValue={item?.price}
                                                    // value="" 
                                                    />
                                                    <p className="USD m-0 p-0">USD</p>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                            <div className="priceRow ">
                                <div className="row ai-center">
                                    <div className="col-lg">
                                        <div className="title">Placements per Search</div>
                                    </div>
                                    {
                                        planData.length > 0 && planData.map((item, i) => (
                                            <div className="col-lg">
                                                <div className="usd-input ">
                                                    <input type="" name={i} onChange={(e) => handlePPsearchchange(e)} defaultValue={item?.PPsearch} />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            {/* switch Row */}
                            <div className="priceRow">
                                <div className="row">
                                    <div className="col-lg">
                                        <div className="title">Outer Glow</div>
                                    </div>

                                    {
                                        planData.length > 0 && planData.map((item, i) => (
                                            <div className="col-lg col-3">
                                                <div className="text-center">
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input" type="checkbox"
                                                            // checked={item?.OuterGlow}
                                                            onChange={(e) => handleOGchange(e)}
                                                            defaultChecked={item?.OuterGlow}
                                                            role="switch" name={i} id="flexSwitchCheckDefault" />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }


                                </div>
                            </div>

                            <div className="priceRow ">
                                <div className="row ai-center">
                                    <div className="col-lg">
                                        <div className="title">Advertisement Text</div>
                                    </div>
                                    {
                                        planData.length > 0 && planData.map((item, i) => (
                                            <div className="col-lg">
                                                <div className="usd-input ">
                                                    <input type="" name={i} onChange={(e) => handleAdschange(e)} defaultValue={item?.ads} />
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                            <div className="btnRow">
                                <div className="row jc-fe ai-center">
                                    <div className="col-lg col-12">
                                    </div>

                                    {
                                        planData.length > 0 && planData.map((item, i) => (
                                            <div className="col-lg col-md-3 col-6 mb-2 mb-md-0">
                                                <button style={{ background: "#20c7d4" }} className={`btn1 planCard`} onClick={() => updateplan(i)}>Submit</button>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>                        </>
                        :
                        <>
                            {/* for tab and mobile Screen */}
                            <div className="priceRow ">
                                <div className="row ai-center">
                                    <div className="col-lg">
                                        <div className="title">Price</div>
                                    </div>
                                    {plan == "Standard" &&
                                        <div className="col-lg">
                                            <div className="usd-input ">
                                                <input type="" placeholder="10.00" />
                                                <p className="USD m-0 p-0">USD</p>
                                            </div>
                                        </div>
                                    }
                                    {plan == "Bronze" &&
                                        <div className="col-lg">
                                            <div className="usd-input ">
                                                <input type="" placeholder="10.00" />
                                                <p className="USD m-0 p-0">USD</p>
                                            </div>
                                        </div>
                                    }
                                    {plan == "Silver" &&
                                        <div className="col-lg">
                                            <div className="usd-input ">
                                                <input type="" placeholder="10.00" />
                                                <p className="USD m-0 p-0">USD</p>
                                            </div>
                                        </div>
                                    }
                                    {plan == "Gold" &&
                                        <div className="col-lg">
                                            <div className="usd-input ">
                                                <input type="" placeholder="10.00" />
                                                <p className="USD m-0 p-0">USD</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="priceRow ">
                                <div className="row ai-center">
                                    <div className="col-lg">
                                        <div className="title">Placements per Search</div>
                                    </div>
                                    {plan == "Standard" &&
                                        <div className="col-lg">
                                            <div className="usd-input ">
                                                <input type="" placeholder="0" />
                                            </div>
                                        </div>
                                    }
                                    {plan == "Bronze" &&
                                        <div className="col-lg">
                                            <div className="usd-input ">
                                                <input type="" placeholder="1" />
                                            </div>
                                        </div>
                                    }
                                    {plan == "Silver" &&
                                        <div className="col-lg">
                                            <div className="usd-input ">
                                                <input type="" placeholder="2" />
                                            </div>
                                        </div>
                                    }
                                    {plan == "Gold" &&
                                        <div className="col-lg">
                                            <div className="usd-input ">
                                                <input type="" placeholder="3" />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* switch Row */}
                            <div className="priceRow">
                                <div className="row jc-center">
                                    <div className="col-lg">
                                        <div className="title">Outer Glow</div>
                                    </div>
                                    {plan == "Standard" &&
                                        <div className="col-lg col-3">
                                            <div className="text-center">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox"
                                                        // checked={item?.visible}
                                                        role="switch" id="flexSwitchCheckDefault" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {plan == "Bronze" &&
                                        <div className="col-lg col-3">
                                            <div className="text-center">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox"
                                                        // checked={item?.visible}
                                                        role="switch" id="flexSwitchCheckDefault" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {plan == "Silver" &&
                                        <div className="col-lg col-3">
                                            <div className="text-center">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox"
                                                        // checked={item?.visible}
                                                        role="switch" id="flexSwitchCheckDefault" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {plan == "Gold" &&
                                        <div className="col-lg col-3">
                                            <div className="text-center">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox"
                                                        // checked={item?.visible}
                                                        role="switch" id="flexSwitchCheckDefault" />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                            <div className="priceRow ">
                                <div className="row ai-center">
                                    <div className="col-lg">
                                        <div className="title">Advertisement Text</div>
                                    </div>
                                    {plan == "Standard" &&
                                        <div className="col-lg">
                                            <div className="usd-input ">
                                                <input type="" placeholder="Normal Listing" />
                                            </div>
                                        </div>
                                    }
                                    {plan == "Bronze" &&
                                        <div className="col-lg">
                                            <div className="usd-input ">
                                                <input type="" placeholder="Up to 1.5x more impressions" />
                                            </div>
                                        </div>
                                    }
                                    {plan == "Silver" &&
                                        <div className="col-lg">
                                            <div className="usd-input ">
                                                <input type="" placeholder="Up to 2x more impressions" />
                                            </div>
                                        </div>
                                    }
                                    {plan == "Gold" &&
                                        <div className="col-lg">
                                            <div className="usd-input ">
                                                <input type="" placeholder="Up to 5x more impressions" />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    }

                </div>

            </div>
            {
                showLoader &&
                <div className='loader'>
                    <img src={loader} alt="loader" />
                </div>
            }
        </div>
    );
};

export default AdminSetting;






































