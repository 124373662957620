import { combineReducers } from "redux";
import NetworkReducer from "./validateNetwork";
import authReducer from "./auth";
import tradesReducer from "./tradesReducer";
import chatListReducer from "./chatListReducer";
import currentChatReducer from "./currentChatReducer";
import friendNameReducer from "./friendNameReducer";


const rootReducer = combineReducers({
    authReducer,
    tradesReducer,
    chatListReducer,
    friendNameReducer,
    currentChatReducer,
    NetworkReducer
})

export default rootReducer;