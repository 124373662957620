const initialState = {
    isConnected: false,
    wallet: "Connect Wallet",
    id: "",
    email: "",
    role: "",
    username: "",
    isVerified: false,
    timestamp : "",
    kycStatus: "not-verified",
    aboutYou: "",
    country: "",
};

const Login_REDUCER = (state = initialState, action) => {
    switch (action.type) {
        case "Login":
            return action.payload || state;
            
        case "Logout":
            return initialState;

        default:
            return state;
    }
}

export default Login_REDUCER;