import axios from "../axios/index"
import { URL } from "../config"

const token = sessionStorage.getItem("token")
const api = {};



api.getofferByID = function (body) {
    const url = `${URL}/offer/find-offer-by-Id/`;
    return axios.post(url, body);
}


api.getoffers = function (body) {
    const url = `${URL}/offer/get-offers-admin`;
    return axios.post(url, body);
}


api.getoffersLength = function (body) {
    const url = `${URL}/offer/get-offers-length-admin`;
    return axios.get(url, body);
}


api.getoffersbystatus = function (body) {
    const url = `${URL}/offer/get-offers-status`;
    return axios.post(url, body);
}


api.updateofferstatus = function (body) {
    const url = `${URL}/offer/updateofferStatusbyAdmin`;
    return axios.post(url, body, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .catch(err => {
            return {
                success: err.success,
                message: err.message
            };
        })
}

api.getplans = function (body) {
    const url = `${URL}/admin/plan/get-plans`;
    return axios.get(url, body);
}

api.updateplans = function (body) {
    const url = `${URL}/admin/plan/update-plan`;
    return axios.post(url, body, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .catch(err => {
            return {
                success: err.success,
                message: err.message
            };
        })
}


api.getproductType = function (body) {
    const url = `${URL}/admin/producttype/get-product-type`;
    return axios.get(url, body);
}


api.updateproductType = function (body) {
    const url = `${URL}/admin/producttype/update-product-type`;
    return axios.post(url, body, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .catch(err => {
            return {
                success: err.success,
                message: err.message
            };
        })
}

api.createproductType = function (body) {
    const url = `${URL}/admin/producttype/add-product-type`;
    return axios.post(url, body, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .catch(err => {
            return {
                success: err.success,
                message: err.message
            };
        })
}

api.createcategories = function (body) {
    const url = `${URL}/admin/category/add-category`;
    return axios.post(url, body, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .catch(err => {
            return {
                success: err.success,
                message: err.message
            };
        })
}

api.getcategories = function (body) {
    const url = `${URL}/admin/category/get-categories`;
    return axios.get(url, body);
}
api.getVariants = function (body) {
    const url = `${URL}/get-variants-id`;
    return axios.post(url, body);
}
api.updatecategories = function (body) {
    const url = `${URL}/admin/category/update-category`;
    return axios.post(url, body, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .catch(err => {
            return {
                success: err.success,
                message: err.message
            };
        })
}

api.getanalyticsData = function (body) {

    const url = `${URL}/offer-analytics`;
    return axios.post(url, body);
}

api.getanalyticsDataAllTime = function (body) {

    const url = `${URL}/offer-analytics-all-time`;
    return axios.post(url, body);
}



api.GraphData = function (body) {
    const url = `${URL}/api/dates`;
    return axios.post(url, body);
}

api.GraphData = function (body) {
    const url = `${URL}/api/dates`;
    return axios.post(url, body);
}



export default api;