import { useState, useEffect } from "react";
import { URL } from "../config";
import axios from "axios"
import view from '../images/view.svg'

import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, chatList, currentChat, friendName, emptyFriendName } from '../redux/actions/actions';

// ------------------------------
import jwt from "jwt-decode";
// ==============================


export const AllUsers = ({ darkMode }) => {
  const [search, setSearch] = useState("")
  const [Users, setUsers] = useState([])
  const [filter, setFilter] = useState("")

  const navigate = useNavigate();
  // ------------------------------
  const token = sessionStorage.getItem("token")
  // ==============================
  const dispatch = useDispatch();
  const authData = token ? jwt(token)["user"][0] : null


  async function getUsers() {
    try {
      let thisURL;
      if (typeof search != "undefined" && search != null && search != "")
        thisURL = `${URL}/admin/all-users?search=${search}`

      else if (typeof search != "undefined" && filter != null && filter != "" && filter == "isNotVerified")
        thisURL = `${URL}/admin/all-users?isVerified=${false}`

      else if (typeof filter != "undefined" && filter != null && filter != "")
        thisURL = `${URL}/admin/all-users?kycStatus=${filter}`

      else
        thisURL = `${URL}/admin/all-users`

      await axios.get(thisURL)
        .then(res => {
          console.log(res.data);
          if (res.data.msg == "done") {
            setUsers(res.data.users)
          }
          res.data.error && alert(res.data.error)
        })
        .catch(err => {
          console.log(err);
        })
    } catch (error) {
      console.log(error);
    }
  }

  function timeConverter(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
  }

  function fromWeitoEth(value) {
    return parseFloat(value) / 1000000000000000000
  }

  useEffect(() => {
    // ------------------------------
    if (!token || typeof token == "undefined") {
      navigate("/")
    }
    // ==============================
    else {
      getUsers()
    }
  }, [])


  return (
    <section className={darkMode ? "allTrades darkMode" : "allTrades"}>
      <div className="container">
        <div className="tradeTable">

          <div className="alltradesR1">
            <div className="userSearchMain pb-10-lg">
              <select name="" placeholder="Select Status" value={filter} onChange={(e) => setFilter(e.target.value)} >
                <option value="" >Select Status</option>
                <option value="verified">KYC Verified</option>
                <option value="pending">KYC Pending</option>
                <option value="not-verified">KYC Not Verified</option>
                <option value="isNotVerified">Banned Users</option>
              </select>
              <button className="btn sBtn" onClick={() => getUsers()}>Filter by Status</button>
            </div>

            <div className="userSearchMain">
              <input placeholder="Enter username/email/wallet" type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
              <button className="btn sBtn" onClick={() => getUsers()}>Search</button>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
               <tr>
                  <th scope="col" className='cursorP'  >USERNAME</th>
                  <th scope="col">EMAIL</th>
                  <th scope="col">WALLET</th>
                  <th scope="col">TOTAL TRADES</th>
                  <th scope="col">COMPLETED TRADES </th>
                  <th scope="col">RATING SCORE</th>
                  <th scope="col">ACC. VERIFIED</th>
                  <th scope="col">KYC VERIFIED</th>
                  <th scope="col">COUNTRY</th>
                  <th scope="col">REGISTERED AT</th>
                  <th scope="col">DETAILS</th>
                </tr>
              </thead>
              <tbody className='pb-2'>
                {
                  Users?.map((user, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">{user?.username}</th>
                        <td>{user?.email}</td>
                        <td style={{ wordBreak: "break-all" }}> <div style={{ maxWidth: "171px", display: "inlineBlock",overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{user?.wallet}</div> </td>
                        <td style={{ textAlign: "center" }}> {user?.totalTrades} </td>
                        <td style={{ textAlign: "center" }}> {user?.completedTrades} </td>
                        <td style={{ textAlign: "center" }}> {(user?.rating).toFixed(1)}</td>
                        <td style={{ textAlign: "center" }}> {(user?.isVerified ? "VERIFIED" : "NOT VERIFIED")}</td>
                        <td style={{ textAlign: "center" }}> {(user?.kycStatus).toUpperCase()}</td>
                        <td style={{ textAlign: "center" }}> {typeof user?.country == "undefined" ? "---" : user?.country}</td>
                        <td>{(user?.timestamp)}</td>
                        <td><Link to={`/userDetail/${user?._id}`} className='btn viewBtn' ><img src={view} alt="" /> View</Link></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>


          {/* <div className='text-center'><button className='btn showMore'>Show more</button></div> */}


        </div>
      </div>
    </section>
  )
}