import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import axios from 'axios';
import { URL } from '../config';

import moment from "moment"

// import GetNotify from "../constant/Notification"

import ScrollToBottom from "react-scroll-to-bottom"

import { Modal, Button, ProgressBar } from 'react-bootstrap'

import star from "../images/star.png"
import startImg from "../images/startImg.png"
import loaderImg from '../images/loader.gif'
import attachment from '../images/attachment.svg'
import loader from '../images/loader.gif'
import fileLogo from "../images/fileLogo.svg"
import send from '../images/send.svg'
import verified from '../images/verified.svg'

import { useSelector, useDispatch } from "react-redux";
import { login, chatList, currentChat, friendName, emptyFriendName } from '../redux/actions/actions';

import jwt from "jwt-decode";

/*
* yay check kerne k liay hay k startChat function agr call hogaya hay 
* useEffect main aik bar to dosri bar call na ho
*/
let chatstarted = false
let thisRoomID = ""

function Chat({ socket, tradeData }) {
    const navigate = useNavigate();
    const location = useLocation()
    const token = sessionStorage.getItem("token")
    const bottomRef = useRef(null);

    const dispatch = useDispatch();
    const ID = useParams().id;
    const authData = token ? jwt(token)["user"][0] : null
    const myFriendName = useSelector((state) => state.friendNameReducer)

    const [replyID, setReplyID] = useState("");
    const [replyText, setReplyText] = useState("");
    const [showReply, setShowReply] = useState(false);
    const [replyType, setShowReplyType] = useState("");

    const myCurrentChat = useSelector((state) => state.currentChatReducer)
    const chatListReduxData = useSelector((state) => state.chatListReducer)
    const [currentMessage, setCurrentMessage] = useState("");
    const [messageList, setMessageList] = useState([]);
    const [socketID, setSocketID] = useState("");
    const [fileUplaodProgress, setFileUplaodProgress] = useState("");
    const [showFileProgressBar, setShowhowFileProgressBar] = useState(false)
    const [userIsOnline, setUserIsOnline] = useState(false);
    const [friendIsOnline, setFriendIsOnline] = useState(false);
    const [temp, setTemp] = useState(false);
    const [img, setImg] = useState("");
    let msgOffset = 0;
    var testList = messageList


    const [buyer, setBuyer] = useState([]);
    const [seler, setSeller] = useState([]);


    async function startChat() {
        await axios.post(`${URL}/admin/chat/join-chat`, {
            member1: tradeData?.from?._id,
            member2: tradeData?.to?._id,
            tradeID: ID
        })
            .then(res => {
                console.log("res.data start");
                console.log(res.data);
                if (res.data.msg == 'chatDataFound') {
                    dispatch(currentChat(res.data.chatData))

                    if (typeof res?.data?.chat != "undefined" && (res?.data?.chat).length > 0) {
                        const strArr1 = res?.data?.chat;
                        const descArr = strArr1.sort().reverse();
                        console.log(descArr); // 👉️
                        dispatch(chatList(descArr))
                        setMessageList(descArr);
                        msgOffset += (res?.data?.chat).length

                        // Msg seen wala system of kerdia hay
                        // const UnSeenMsg = []
                        // descArr.forEach(e => {
                        //     if (authData?.username != e?.author && e.seen == false) {
                        //         UnSeenMsg.push(e._id)
                        //     }
                        // })
                        // console.log("UnSeenMsg");
                        // console.log("UnSeenMsg");
                        // console.log("UnSeenMsg");
                        // console.log(UnSeenMsg);
                        // console.log(descArr[0].tradeID);
                        // console.log(authData?.username);
                        // if (UnSeenMsg.length > 0) {
                        //     socket.emit("I_just_saw_a_msg", {
                        //         roomID: res.data.chatData?._id,
                        //         friendID: friendID,
                        //         msgId: UnSeenMsg
                        //     });
                        // }
                    }

                    var messageBody = document.querySelector('.chatDiv');
                    messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
                }
                res.data.error && alert(res.data.error)

            })
            .catch(err => {
                console.log(err);
            })
        // }
    }

    function fileUploaded(e) {
        e.preventDefault()
        try {
            console.log(e.target);
            // alert(showReply)
            // alert(replyID)

            const mimeType = ["png", "gif", "jpg", "jpeg", "JSON", "json", "doc", "docx", "txt"]
            if (e.target.files[0]) {
                const formData = new FormData();
                let totalFileSize = 0;

                for (const file of e.target.files) {
                    const fileExt = ((file.name).slice((Math.max(0, (file.name).lastIndexOf(".")) || Infinity) + 1).toLowerCase());
                    console.log("fileExt -> ", fileExt);

                    const fileSize = file.size / 1024 / 1024; // in MiB
                    console.log(fileSize);
                    totalFileSize += fileSize;

                    if (totalFileSize > 5) {
                        alert('Total File size exceeds 5 MiB');
                        return

                    } else if (mimeType.includes(fileExt) == true) {
                        console.log(file);
                        formData.append('imgs', file) // appending every file to formdata

                    } else {
                        alert("invalid file type")
                        return
                    }
                }
                console.log("123");

                formData.append("seen", true);
                formData.append("isAdmin", true);
                formData.append("roomID", myCurrentChat._id);
                formData.append("author", authData?.username);
                formData.append("tradeID", (ID).substring(0, 24));
                formData.append("id", authData?._id);
                formData.append("type", "img");
                formData.append("timestamp", new Date().toUTCString());
                if (showReply && replyID != "") formData.append("tag", replyID);

                const Config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                };
                console.log(formData);
                console.log(Config);

                setShowhowFileProgressBar(true)
                axios.post(`${URL}/chat/save-file`, formData, {
                    onUploadProgress: progressEvent => {
                        console.log(`upload progress: ${Math.round(progressEvent.loaded / progressEvent.total * 100)}%`);

                        setFileUplaodProgress(Math.round(progressEvent.loaded / progressEvent.total * 100))
                        if (Math.round(progressEvent.loaded / progressEvent.total * 100) == 100) {
                            setShowhowFileProgressBar(false)
                            setFileUplaodProgress(0)
                        }
                    }
                })
                    .then(async response => {
                        console.log("response");
                        console.log(response);
                    })
            }

        } catch (error) {
            console.log(error);
            setShowhowFileProgressBar(false)
        }
    }

    const sendMessage = async () => {
        sessionStorage.setItem("rte453rdtfcfg", myCurrentChat._id)
        console.log("myCurrentChat", myCurrentChat);
        console.log("messageList", messageList);
        if (currentMessage !== "") {
            const messageData = {
                roomID: myCurrentChat._id,
                seen: true,
                author: authData?.username,
                id: authData?._id,
                isAdmin: true,
                msg: currentMessage,
                type: "text",
                tradeID: (ID).substring(0, 24),
                timestamp: new Date().toUTCString(),
            };
            if (showReply && replyID != "") messageData.tag = replyID
            await socket.emit("send_message", messageData);
            setCurrentMessage("")
            setShowReply(false)
        }
    };

    const getChatDiv_height = () => {
        var messageBody = document.querySelector('.chatDiv');
        document.querySelector(".chatDiv").addEventListener("scroll", myFunction);

        async function myFunction() {
            if (Math.abs(messageBody.scrollTop) == 0) {
                console.log("=-=-=-=-=-=-=", myCurrentChat);
                await axios.post(`${URL}/chat/fetch-msg`, {
                    "roomID": myCurrentChat._id,
                    "offset": msgOffset
                })
                    .then(res => {
                        console.log("messageList");
                        console.log(messageList);
                        console.log("res.data");
                        console.log("res.data");
                        console.log(res.data.msgList);
                        if (res.data.msgList) {
                            // dispatch(currentChat(res.data.chatData))

                            const strArr1 = res?.data?.msgList;
                            const descArr = strArr1.sort().reverse();

                            dispatch(chatList([...descArr, ...chatListReduxData]))
                            setMessageList((list) => [...(descArr), ...list]);
                            msgOffset += (res?.data?.msgList).length

                            // var messageBody = document.querySelector('.chatDiv');
                            messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
                        }
                        res.data.error && alert(res.data.error)

                    })
                    .catch(err => {
                        console.log(err);
                    })

            }

        }
    }

    // Start/fetch chat useeffect
    useEffect(() => {
        console.log("chatstarted: ", chatstarted);
        if (chatstarted == false && tradeData != null) {
            console.log("------- 1st Start -------");

            setBuyer(tradeData?.from)
            setSeller(tradeData?.to)

            setTimeout(() => {
                startChat()
                getChatDiv_height()
                chatstarted = true
                console.log("------- 1st End -------");

            }, 1000);
        }

    }, [tradeData])

    // join and disconnect chat useeffect
    useEffect(() => {
        if (Object.keys(myCurrentChat).length > 0 && chatstarted == true) {
            !socket.connected && socket.connect();

            console.log("------- 2nd Start ------- myCurrentChat ", myCurrentChat);
            // alert(JSON.stringify(authData?._id))
            socket.emit("join_room", {
                roomID: myCurrentChat._id,
                id: authData?._id
            });

            /*
            * yahan pay false is liay kia hay takay jb dosri trade k page per jai user to usko chatstarted=false mile
            * na k true or agr false na kerrain to user ko true milra tha dosri trade main
            */
            chatstarted = false
        }

        if (socketID == "") {
            socket.on("onJoinEvent", ({ roomID, socketid }) => {
                console.log("user join");
                setSocketID(socketid)
            });
        }

        socket.on("user_disconnect", (data) => {
            console.log("user_disconnect");
            console.log(JSON.stringify(data));
        })


        console.log("------- 2nd End -------");

    }, [socket, myCurrentChat]);

    // receive msg and img useeffect
    // seen msg useeffect
    useEffect(() => {
        // return () => {
            socket.on("receive_message", (data) => {
                startChat()
                getChatDiv_height()

                // const edr45r65 = sessionStorage.getItem("rte453rdtfcfg")
                // console.log("edr45r65 ", edr45r65);
                // console.log("myCurrentChat?._id ", myCurrentChat?._id);
                // // if(edr45r65 == myCurrentChat?.id) {
                // console.log("receive_message");
                // console.log(data);
                // console.log(messageList);
                // console.log(messageList.length);
                // testList = messageList
                // setMessageList((list) => [...list, data]);
    
                // var messageBody = document.querySelector('.chatDiv');
                // messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    
                // window.scrollTo(0, 0)
                // console.log("messageBody.scrollHeight", messageBody.scrollHeight);
                // console.log("messageBody.clientHeight", messageBody.clientHeight);
    
                // // } else {
                // //     console.log("dosre room say aaya msg");
                // // }
            });
    
            socket.on("receive_img", (data) => {
                console.log("receive_img");
                console.log(data);
                dispatch(chatList([...chatListReduxData, ...data]))
                setMessageList((list) => [...list, ...data]);
    
                var messageBody = document.querySelector('.chatDiv');
                messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
            })
            // testing k liay comment kia hay or koi masla nahi
            // socket.on("msgseen", (friendID) => {
            //     console.log("msgseen");
            //     console.log(friendID);
            //     // alert("seen msg")
            //     startChat(friendID)
            // })
    
            // window.onbeforeunload = function () {
            //     return 'Are you sure you want to leave?';
            // };
        // }


    }, [socket])


    function timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        // var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ', ' + hour + ':' + min;
        return time;
    }

    function dateConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = date + ', ' + month + ' ' + year;
        return time;
    }

    function handleReplyClick(chat) {
        setShowReply(true)
        setReplyID(chat._id)
        if (chat.type.toLowerCase() == "jpeg" || chat.type?.toLowerCase() == "jpg" || chat.type?.toLowerCase() == "png" || chat.type?.toLowerCase() == "gif") {
            setReplyText(`${URL}/fetchChatFiles/${chat?.file}`)
        }
        else if (chat.type.toLowerCase() == "text") {
            setReplyText(chat.msg)

        } else {
            setReplyText(chat.msg)

        }
        setShowReplyType(chat.type)


    }

    function cancleReply() {
        setShowReply(false)
        setReplyID("")
        setReplyText("")
        setShowReplyType("")
    }

    function handleChangeTxt(e) {
        setCurrentMessage(e.target.value)
    }

    function createMarkup(txt) {
        let newTxt = txt?.replaceAll("\n", "<br />")
        return { __html: newTxt };
    }

    function myFunctionScroll(id, e) {
        e.preventDefault()
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: 'smooth', block: 'nearest'
        });
    }

    const markSeen = index => {
        setTimeout(() => {
            console.log(index);
            console.log(messageList);
            console.log(chatListReduxData);

            // const newSeens = [...messageList];
            // newSeens[index].seen = false;
            // setMessageList(newSeens);

            // const newSeens = [...chatListReduxData];
            // newSeens[index].seen = false;
            // dispatch(chatList([newSeens]))

        }, 1000);

        // setTimeout(() => {
        //     console.log("2 => ", chatListReduxData);
        // }, 2000);
        // setTimeout(() => {
        //     console.log("3 => ", chatListReduxData);
        // }, 3000);
        // setTimeout(() => {
        //     console.log("4 => ", chatListReduxData);
        // }, 4000);
        // setTimeout(() => {
        //     console.log("5 => ", chatListReduxData);
        // }, 5000);
    };




    return (
        <div className="col-lg-6 mb-30">
            <div className="detailCard">
                <h1 onClick={() => console.log(myCurrentChat)}>Chat</h1>
                <div className={showReply ? "chatDiv mxHeight288" : "chatDiv"}>
                    <div className="chatMain" >

                        {
                            messageList?.map((chat, i) => {
                                 return (
                                    authData?.username == chat?.author ?
                                        <div className="sendMsg" key={i}>
                                            {
                                                (chat.type)?.toLowerCase() == "img" || (chat.type)?.toLowerCase() == "jpeg" || (chat.type)?.toLowerCase() == "jpg" || (chat.type)?.toLowerCase() == "png" || (chat.type)?.toLowerCase() == "gif" ?
                                                    <p className='mb-0'>
                                                        <div className="sendImg ">
                                                            <span className='replyImgBtn' onClick={() => handleReplyClick(chat)}>reply</span>
                                                            {
                                                                chat?.tag &&
                                                                <a onClick={(e) => myFunctionScroll(chat?.tag?._id, e)} className='tag w-100'>
                                                                    {
                                                                        ["jpeg", "jpg", "png", "gif"].includes((chat?.tag?.type)) ?
                                                                            <img src={` ${URL}/fetchChatFiles/${chat?.tag?.file} `} width="30px" alt="img try" />
                                                                            :
                                                                            ["txt", "pdf", "doc", "docx", "json"].includes((chat?.tag?.type)) ?
                                                                                <img src={fileLogo} className="" width="30px" alt={i} />
                                                                                :
                                                                                <span>{(chat?.tag?.msg)?.length > 30 ? (chat?.tag?.msg)?.substring(0, 30) + "....." : (chat?.tag?.msg)} </span>
                                                                    }
                                                                </a>
                                                            }
                                                            {/* ==========================================  */}
                                                            <a target='blank' id={`${chat?._id}`} href={`${URL}/fetchChatFiles/${chat?.file}`}>
                                                                <img src={` ${URL}/fetchChatFiles/${chat?.file} `} className={`${chat?.tag && "changeBorder"}`} width="100px" alt={i} />
                                                            </a>

                                                            {/* ==========================================  */}
                                                            {
                                                                chat.seen && <span className='seenDiv'><i className="far fa-check-circle seen"></i></span>
                                                            }
                                                        </div>
                                                    </p>
                                                    :
                                                    (chat.type)?.toLowerCase() == "txt" || (chat.type)?.toLowerCase() == "pdf" || (chat.type)?.toLowerCase() == "doc" || (chat.type)?.toLowerCase() == "docx" || (chat.type)?.toLowerCase() == "json" ?
                                                        <>
                                                            <p className='mb-0'>

                                                                <div className="sendImg ">
                                                                    <span className='replyImgBtn' onClick={() => handleReplyClick(chat)}>reply</span>

                                                                    {
                                                                        chat?.tag &&
                                                                        <a onClick={(e) => myFunctionScroll(chat?.tag?._id, e)} className='tag w-100'>
                                                                            {
                                                                                ["jpeg", "jpg", "png", "gif"].includes((chat?.tag?.type)) ?
                                                                                    <img src={` ${URL}/fetchChatFiles/${chat?.tag?.file} `} width="30px" alt="img try" />
                                                                                    :
                                                                                    ["txt", "pdf", "doc", "docx", "json"].includes((chat?.tag?.type)) ?
                                                                                        <img src={fileLogo} className="" width="30px" alt={i} />
                                                                                        :
                                                                                        <span>{(chat?.tag?.msg)?.length > 30 ? (chat?.tag?.msg)?.substring(0, 30) + "....." : (chat?.tag?.msg)} </span>
                                                                            }
                                                                        </a>
                                                                    }
                                                                    <a target='blank' id={`${chat?._id}`} href={`${URL}/fetchChatFiles/${chat?.file}`}>
                                                                        <img src={fileLogo} width="100px" alt={i} />
                                                                    </a>
                                                                    <span style={{ fontSize: "x-small", lineHeight: "12px", wordBreak: "break-all" }}>{typeof chat?.originalname != "undefined" && chat?.originalname}</span>
                                                                    {
                                                                        chat.seen && <span className='seenDiv'><i className="far fa-check-circle seen"></i></span>
                                                                    }
                                                                </div>
                                                            </p>
                                                        </>
                                                        :
                                                        <p className="mb-0">
                                                            {
                                                                chat?.tag &&
                                                                <a onClick={(e) => myFunctionScroll(chat?.tag?._id, e)} className='tag'>
                                                                    {
                                                                        ["jpeg", "jpg", "png", "gif"].includes((chat?.tag?.type)) ?
                                                                            <img src={` ${URL}/fetchChatFiles/${chat?.tag?.file} `} width="30px" alt="img try" />
                                                                            :
                                                                            ["txt", "pdf", "doc", "docx", "json"].includes((chat?.tag?.type)) ?
                                                                                <img src={fileLogo} className="" width="30px" alt={i} />
                                                                                :
                                                                                <span>{(chat?.tag?.msg)?.length > 30 ? (chat?.tag?.msg)?.substring(0, 30) + "....." : (chat?.tag?.msg)} </span>
                                                                    }

                                                                </a>
                                                            }
                                                            <div className='msgText' onClick={() => markSeen(i)} id={chat._id} dangerouslySetInnerHTML={createMarkup(chat.msg)} />
                                                            <span className='replyBtn' onClick={() => handleReplyClick(chat)}>reply</span>
                                                            {
                                                                chat.seen && <span className='seenDiv'><i className="far fa-check-circle seen"></i></span>
                                                            }                                                                        </p>
                                            }
                                            <div className="time">{moment(chat?.timestamp).fromNow()}</div>
                                        </div>
                                        :

                                        <div className="revieveMsg " key={i}>
                                            {
                                                (chat.type)?.toLowerCase() == "jpeg" || (chat.type)?.toLowerCase() == "jpg" || (chat.type)?.toLowerCase() == "png" || (chat.type)?.toLowerCase() == "gif" ?
                                                    <p className='mb-0' style={{ background: `${chat?.author == buyer.username ? "#D2EED7" : "#CAE6FE"}` }}>
                                                        <div className="receiveImg">
                                                            {/* ==========================================  */}
                                                            <span className='replyImgBtn' onClick={() => handleReplyClick(chat)}>reply</span>
                                                            {
                                                                chat?.tag &&
                                                                <a onClick={(e) => myFunctionScroll(chat?.tag?._id, e)} className='tag'>
                                                                    {
                                                                        ["jpeg", "jpg", "png", "gif"].includes((chat?.tag?.type)) ?
                                                                            <img src={` ${URL}/fetchChatFiles/${chat?.tag?.file} `} width="30px" alt="img try" />
                                                                            :
                                                                            ["txt", "pdf", "doc", "docx", "json"].includes((chat?.tag?.type)) ?
                                                                                <img src={fileLogo} className="" width="30px" alt={i} />
                                                                                :
                                                                                <span>{(chat?.tag?.msg)?.length > 30 ? (chat?.tag?.msg)?.substring(0, 30) + "....." : (chat?.tag?.msg)} </span>
                                                                    }
                                                                </a>
                                                            }
                                                            <a target='blank' id={`${chat?._id}`} href={`${URL}/fetchChatFiles/${chat?.file}`}>
                                                                <img className='img-fluid' src={` ${URL}/fetchChatFiles/${chat?.file} `} width="100px" alt={i} />
                                                            </a>
                                                            {/* ==========================================  */}
                                                        </div>
                                                    </p>
                                                    :
                                                    (chat.type)?.toLowerCase() == "txt" || (chat.type)?.toLowerCase() == "pdf" || (chat.type)?.toLowerCase() == "doc" || (chat.type)?.toLowerCase() == "docx" || (chat.type)?.toLowerCase() == "json" ?
                                                        <>
                                                            <p className='mb-0' style={{ background: `${chat?.author == buyer.username ? "#D2EED7" : "#CAE6FE"}` }}>
                                                                <div className="receiveImg">
                                                                    {
                                                                        chat?.tag &&
                                                                        <a onClick={(e) => myFunctionScroll(chat?.tag?._id, e)} className='tag'>
                                                                            {
                                                                                ["jpeg", "jpg", "png", "gif"].includes((chat?.tag?.type)) ?
                                                                                    <img src={` ${URL}/fetchChatFiles/${chat?.tag?.file} `} width="30px" alt="img try" />
                                                                                    :
                                                                                    ["txt", "pdf", "doc", "docx", "json"].includes((chat?.tag?.type)) ?
                                                                                        <img src={fileLogo} className="" style={{ width: "30px" }} alt={i} />
                                                                                        :
                                                                                        <span>{(chat?.tag?.msg)?.length > 30 ? (chat?.tag?.msg)?.substring(0, 30) + "....." : (chat?.tag?.msg)} </span>
                                                                            }
                                                                        </a>
                                                                    }
                                                                    <span className='replyImgBtn' onClick={() => handleReplyClick(chat)}>reply</span>
                                                                    <a id={`${chat?._id}`} target='blank' href={`${URL}/fetchChatFiles/${chat?.file}`}>
                                                                        <img src={fileLogo} className="receiveFile" width="100px" alt={i} />
                                                                    </a>
                                                                    <span style={{ fontSize: "x-small", lineHeight: "12px", wordBreak: "break-all" }}>{typeof chat?.originalname != "undefined" && chat?.originalname}</span>
                                                                </div>
                                                            </p>
                                                        </>
                                                        :
                                                        <p className="mb-0" style={{ background: `${chat?.author == buyer.username ? "#D2EED7" : "#CAE6FE"}` }}>
                                                            {
                                                                chat?.tag &&
                                                                <a onClick={(e) => myFunctionScroll(chat?.tag?._id, e)} className='tag'>
                                                                    {
                                                                        ["jpeg", "jpg", "png", "gif"].includes((chat?.tag?.type)) ?
                                                                            <img src={` ${URL}/fetchChatFiles/${chat?.tag?.file} `} width="30px" alt="img try" />
                                                                            :
                                                                            ["txt", "pdf", "doc", "docx", "json"].includes((chat?.tag?.type)) ?
                                                                                <img src={fileLogo} style={{ width: "30px" }} className="m-0 p-0" width="30px" alt={i} />
                                                                                :
                                                                                <span>{(chat?.tag?.msg)?.length > 30 ? (chat?.tag?.msg)?.substring(0, 30) + "....." : (chat?.tag?.msg)} </span>
                                                                    }
                                                                </a>
                                                            }
                                                            <div className='msgText text-dark' onClick={() => markSeen(i)} id={chat._id} dangerouslySetInnerHTML={createMarkup(chat.msg)} />
                                                            <span className='replyBtn' onClick={() => handleReplyClick(chat)}>reply</span>
                                                        </p>
                                            }
                                            <div className="time">{chat?.author == buyer.username ? "BUYER" : "SELLER"}, {timeConverter(chat.timestamp)}</div>
                                        </div>
                                )
                            })
                        }
                        {
                            showFileProgressBar == true && <div className='m-1'> <ProgressBar striped variant="success" now={fileUplaodProgress} /> </div>
                        }
                        <div ref={bottomRef} > </div>
                    </div>
                </div>
                <div className="chatMainSec">
                    {
                        showReply &&
                        <div className="replyContainer">
                            <div className='replyInputMain'>
                                <div className="reply">reply</div>
                                {
                                    replyType.toLowerCase() == "jpeg" || replyType?.toLowerCase() == "jpg" || replyType?.toLowerCase() == "png" || replyType?.toLowerCase() == "gif" ?
                                        <img src={replyText} width="30px" alt="img try" />
                                        :

                                        ["txt", "pdf", "doc", "docx", "json"].includes((replyType)) ?
                                            <img src={fileLogo} className="mt-2" width="30px" />

                                            :
                                            replyType.toLowerCase() == "text" ?
                                                <div className="text">{(replyText)?.length > 30 ? (replyText)?.substring(0, 30) + "....." : (replyText)}</div>
                                                :
                                                ""
                                }
                                <div className="cancleBtn" onClick={() => cancleReply()}>x</div>
                            </div>
                            <div className="col2"></div>
                        </div>
                    }
                    {
                        // messageList.length > 0 ?
                            <>
                                <div className="d-flex ai-center chatInputMain">
                                    <div className={showReply ? "masgWriteDiv row mx-2 changeBorder" : "masgWriteDiv row mx-2"} >
                                        <div className="col p-0">
                                            <textarea cols="30" rows="3" type="text" value={currentMessage} onChange={(e) => handleChangeTxt(e)} placeholder="Type a message..."></textarea>
                                        </div>
                                    </div>
                                    <div className="auto-col p-0 d-flex fileMain ai-center">
                                        <label >
                                            <input type="file" multiple="multiple" accept="image/png, image/gif, image/jpeg, application/JSON, .doc, .docx,.ppt, .pptx,.txt" name="" onChange={(e) => fileUploaded(e)} />
                                            <img src={attachment} alt="attachment" className="mr-2" />
                                        </label>
                                        <button onClick={() => sendMessage()} className="border-transparent loadBtnColor loadBtnBg font-weight-bold fs-12 w-100" style={{ border: "none", backgroundColor: "transparent" }}>
                                            <img src={send} alt="send" className="" />
                                        </button>
                                    </div>
                                </div>
                            </>
                            // :
                            // <>
                            //     <div className="d-flex ai-center chatInputMain">
                            //         <div className={showReply ? "masgWriteDiv row mx-2 changeBorder" : "masgWriteDiv row mx-2"} >
                            //             <div className="col p-0">
                            //                 <h1>No Chat Available</h1>
                            //             </div>
                            //         </div>
                            //     </div>
                            // </>
                    }

                </div>
            </div>
        </div>
    )
}

export default Chat;