import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, chatList, currentChat, friendName, emptyFriendName } from '../redux/actions/actions';
import Web3 from "web3"
import loader from '../images/loader.gif'

// ------------------------------
import jwt from "jwt-decode";
// ==============================


const { EscrowToken, MATIC_RPC } = require("../config")
const EscrowABI = require("../eth/EscrowToken_ABI.json")


// export const Token = ({ darkMode, myWeb3 }) => {
export const Token = ({ darkMode }) => {
    const { search } = useLocation();
    const query = new URLSearchParams((search), [search])

    const navigate = useNavigate();
    const token = sessionStorage.getItem("token")
    const dispatch = useDispatch();
    const authData = token ? jwt(token)["user"][0] : null
    const [showLoader, setShowLoader] = useState(false);

    const [disableBTNs, setDisableBTNs] = useState(false)

    const [contract, setContract] = useState([])
    const [totalSupply, setTotalSupply] = useState(0)
    const [address, setAddress] = useState("")
    const [mValue, setmValue] = useState("")
    const [bValue, setbValue] = useState("")
    const [cValue, setcValue] = useState("")
    const [wValue, setwValue] = useState("")
    const [blValue, setblValue] = useState("")
    
    const [myWeb3, setMyWeb3] = useState([])

    async function loadWeb3() {
        console.log(MATIC_RPC);
        let myWeb31;
        if (window.ethereum) {
            myWeb31 = await new Web3(window.ethereum)
        } else {
            alert("Metamask not connected so only reading available")
            myWeb31 = await new Web3(MATIC_RPC)
        }
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        console.log("chainId", chainId);
        
        console.log("myWeb3");
        console.log("myWeb3");
        console.log("myWeb3");
        if (chainId != "0x13881") {
            alert("Connect to Correct Network")
            setDisableBTNs(true)
            return false
        }
        const escwContract = await new myWeb31.eth.Contract(EscrowABI, EscrowToken)
        
        
        
        setMyWeb3(myWeb31)
        console.log(escwContract);
        setContract(escwContract)

        await escwContract.methods.totalSupply().call()
            .then(supply => {
                console.log(supply);
                setTotalSupply(myWeb31.utils.fromWei(supply, "ether"))
            })
            .catch(err => console.log(err))
    }

    async function Owner() {
        const a1 = await myWeb3.eth.getAccounts()
        contract.methods._Owner().call()
            .then(wallet => {
                console.log("connected wallet", a1[0])
                console.log("admin address", wallet);
                if (wallet != a1[0]) {
                    alert("Only owner have access")
                    throw "Only owner have access"
                }
            })
            .catch(err => {
                console.log(err)
                return
            })
    }

    async function Minting() {
        if (mValue == "" || mValue == null) {
            alert("Enter input value")
            return
        }

        const amount = await myWeb3.utils.toWei(mValue, "ether")
        setShowLoader(true)
        await Owner()
        contract.methods.addSupply(amount).send({ from: authData?.wallet })
            .then(done => {
                console.log(done);
                alert("Tokens minted")
                contract.methods.totalSupply().call()
                    .then(supply => {
                        console.log(supply);
                        setTotalSupply(myWeb3.utils.fromWei(supply, "ether"))
                        setShowLoader(false)
                        setmValue(0)
                    })
                    .catch(err => {
                        console.log(err)
                        setShowLoader(false)
                    })
            })
            .catch(err => {
                console.log(err)
                setShowLoader(false)
            })
    }

    async function Burning() {
        if (bValue == "" || bValue == null) {
            alert("Enter input value")
            return
        }

        setShowLoader(true)
        await Owner()
        const amount = await myWeb3.utils.toWei(bValue, "ether")
        contract.methods._burn(amount).send({ from: authData?.wallet })
            .then(done => {
                console.log(done);
                alert("Tokens burned")
                contract.methods.totalSupply().call()
                    .then(supply => {
                        console.log(supply);
                        setTotalSupply(myWeb3.utils.fromWei(supply, "ether"))
                        setShowLoader(false)
                        setbValue(0)
                    })
                    .catch(err => {
                        console.log(err)
                        setShowLoader(false)
                    })
            })
            .catch(err => {
                console.log(err)
                setShowLoader(false)
            })
    }

    async function checkAddress() {
        if (cValue == "" || cValue == null) {
            alert("Enter input value")
            return
        }
        contract.methods.BlackList(cValue).call()
            .then(done => {
                console.log(done);
                if (done) {
                    alert("This address is black listed")
                } else {
                    alert("This address is white listed")
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    async function WhiteListing() {
        if (wValue == "" || wValue == null) {
            alert("Enter input value")
            return
        }

        setShowLoader(true)
        await Owner()
        contract.methods.RemoveFromBlackList(wValue).send({ from: authData?.wallet })
            .then(done => {
                console.log(done);
                alert("Address is now black listed.")
                setShowLoader(false)
            })
            .catch(err => {
                console.log(err)
                setShowLoader(false)
            })
    }

    async function BlackListing() {
        if (blValue == "" || blValue == null) {
            alert("Enter input value")
            return
        }

        setShowLoader(true)
        await Owner()
        contract.methods.AddToBlackList(blValue).send({ from: authData?.wallet })
            .then(done => {
                console.log(done);
                alert("Address is now black listed.")
                setShowLoader(false)
            })
            .catch(err => {
                console.log(err)
                setShowLoader(false)
            })
    }


    useEffect(() => {
        if (!token || typeof token == "undefined") { navigate("/") }

        loadWeb3()
        // if (myWeb3.length != 0) loadWeb3()

        if (query.get("wallet") != null) setblValue(query.get("wallet"))
        console.log(query.get("wallet"));

    }, [])



    return (
        <section className={darkMode ? "allWalletMain darkMode" : "allWalletMain"} >
            <div className="container">
                <div className="row manageTokenSection">

                    {/* 1st  */}
                    <div className="col-lg-7 col-md-9 mb-4">
                        <div className="submain p-4 mts">

                            <div className="">
                                <div className="ts1">
                                    <h2 >Escrow Token</h2>
                                </div>
                            </div>

                            <section className="sec54">
                                <div className="lable">
                                    <label>Total Supply</label>
                                </div>
                                <div className="firstcont">
                                    <div className="input">
                                        <input readOnly style={{ fontSize: "small" }} type="text" value={`${totalSupply} ESCW`} />
                                    </div>
                                    <div className="btnc mtsBtn none1" style={{ visibility: "hidden" }}>
                                        <button></button>
                                    </div>
                                </div>
                            </section>

                            <section className="sec54">
                                <div className="lable">
                                    <label>Mint Tokens</label>
                                </div>
                                <div className="firstcont">
                                    <div className="input">
                                        <input placeholder="Enter of tokens" style={{ fontSize: "small" }} type="text" value={mValue} onChange={(e) => setmValue(e.target.value)} />
                                    </div>
                                    <div className="btnc mtsBtn">
                                        <button disabled={disableBTNs} onClick={() => Minting()}>Mint Now</button>
                                    </div>
                                </div>
                            </section>

                            <section className="sec54">
                                <div className="lable">
                                    <label>Burn Tokens</label>
                                </div>
                                <div className="firstcont">
                                    <div className="input">
                                        <input placeholder="Enter of tokens" style={{ fontSize: "small" }} type="text" value={bValue} onChange={(e) => setbValue(e.target.value)} />
                                    </div>
                                    <div className="btnc mtsBtn">
                                        <button disabled={disableBTNs} onClick={() => Burning()}>Burn Now</button>
                                    </div>
                                </div>
                            </section>

                            <section className="sec54">
                                <div className="lable">
                                    <label>Check Address</label>
                                </div>
                                <div className="firstcont">
                                    <div className="input">
                                        <input placeholder="Enter address" style={{ fontSize: "small" }} type="text" value={cValue} onChange={(e) => setcValue(e.target.value)} />
                                    </div>
                                    <div className="btnc mtsBtn">
                                        <button disabled={disableBTNs} onClick={() => checkAddress()}>Check</button>
                                    </div>
                                </div>
                            </section>

                            <section className="sec54">
                                <div className="lable">
                                    <label>WhiteList Address</label>
                                </div>
                                <div className="firstcont">
                                    <div className="input">
                                        <input placeholder="Enter address" style={{ fontSize: "small" }} type="text" value={wValue} onChange={(e) => setwValue(e.target.value)} />
                                    </div>
                                    <div className="btnc mtsBtn">
                                        <button disabled={disableBTNs} onClick={() => WhiteListing()}>WhiteList Now</button>
                                    </div>
                                </div>
                            </section>

                            <section className="sec54">
                                <div className="lable">
                                    <label>BlackList Address</label>
                                </div>
                                <div className="firstcont">
                                    <div className="input">
                                        <input placeholder="Enter address" style={{ fontSize: "small" }} type="text" value={blValue} onChange={(e) => setblValue(e.target.value)} />
                                    </div>
                                    <div className="btnc mtsBtn">
                                        {
                                            query.get("wallet") != null ?
                                                <button disabled={disableBTNs} style={{ backgroundColor: "#dc3545" }} onClick={() => BlackListing()}>BlackList Now</button>
                                                :
                                                <button disabled={disableBTNs} onClick={() => BlackListing()}>BlackList Now</button>
                                        }
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>

                </div>
            </div>

            {
                showLoader &&
                <div className='loader'>
                    <img src={loader} alt="loader" />
                </div>

            }

        </section>
    )
}