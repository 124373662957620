import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { network, networkno } from '../redux/actions/actions';

import logo from '../images/logo.svg'
import globe from '../images/globe.png'
import sunLight from '../images/sun.svg'
import sunDark from '../images/sunDark.svg'
import moon from '../images/moon.svg'
import moonLight from '../images/moonLight.svg'
import { LanguageDropdown } from "./languageDropdown";



export const Navbar = ({ darkMode, handleDark, connetedData, Logout, logingHandler }) => {
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token")
  const authData = useSelector((state) => state.authReducer)
  const isNetworkValid__ = useSelector((state) => state.NetworkReducer)

  // Validate Network
  window.ethereum.on('chainChanged', (chainId) => {
    if(chainId == "0x5" || chainId == "0x61" || chainId == "0x13881") dispatch(network());
    else dispatch(networkno());
  });

  return (
    <nav className={darkMode ? "darkMode navbar navbar-expand-lg navbar-light  customNavMain" : "navbar navbar-expand-lg navbar-light  customNavMain"} >

      {/* <ToastContainer style={{ width: "max-content" }} /> */}

      <Link className="navbar-brand" to="#">
        <img src={logo} alt="Escrow Trade" />
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item active mx-2">
            <NavLink className="nav-link" to="/dashboard">Overview </NavLink>
          </li>

          <li className="nav-item active mx-2">
            <NavLink className="nav-link" to="/allTrades">All Trades </NavLink>
          </li>
          <li className="nav-item active mx-2">
            <NavLink className="nav-link" to="/allUsers">All Users </NavLink>
          </li>
          <li className="nav-item active mx-2">
            <NavLink className="nav-link" to="/all-offers">All Offers </NavLink>
          </li>
          <li className="nav-item active mx-2">
            <NavLink className="nav-link" to="/manage-token">Manage Token </NavLink>
          </li>
          <li className="nav-item active mx-2">
            <NavLink className="nav-link" to="/setting">Settings </NavLink>
          </li>
          <li className="nav-item active mx-2">
            <NavLink className="nav-link" to="/admin-settings">Admin Settings </NavLink>
          </li>

        </ul>

        <div className="darkModeMain">
          <div className="d-flex ai-center">
            {/* <div className="dropdown languageDropdown">
              <div className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                <img className="globe" src={globe} alt="escrow" />
              </div>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <LanguageDropdown />
              </div>
            </div> */}

            <div className="darkMaodeToggle">
              <div className={!darkMode ? "imgDiv active" : "imgDiv"} onClick={() => handleDark(false)}>
                {darkMode ?
                  <img src={sunDark} alt="sun" />
                  :
                  <img src={sunLight} alt="sun" />
                }

              </div>
              <div className={darkMode ? "imgDiv mr-0 active" : "imgDiv mr-0"} onClick={() => handleDark(true)}>
                {darkMode ?
                  <img src={moon} alt="" />
                  :
                  <img src={moonLight} alt="" />
                }
              </div>
            </div>
          </div>

          {!authData?.isConnected ?
            <button className='btn connectBtn' onClick={() => logingHandler()}> Connect Wallet </button>
            :
            <div className="dropdown">
              <button className={`btn connectBtn dropdown-toggle ${!isNetworkValid__ && "invalidNetwork"}`} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onDoubleClick={() => Logout()} >
                {authData?.wallet.substring(0, 10) + "..."}
              </button>

              <div className={`dropdown-menu ${!isNetworkValid__ && "invalidNetwork"}`} aria-labelledby="dropdownMenuButton">
                <button className="dropdown-item" onClick={() => Logout()}>Disconnect</button>
              </div>
            </div>
          }

        </div>
      </div>
    </nav>
  )
}