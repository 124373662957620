import { useState, useEffect } from "react";
import { URL } from "../config";
import axios from "axios"
import view from '../images/view.svg'

import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, chatList, currentChat, friendName, emptyFriendName } from '../redux/actions/actions';

// ------------------------------
import jwt from "jwt-decode";
// ==============================


export const AllTrades = ({ darkMode }) => {
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState("")
  const [Trades, setTrades] = useState([])

  const navigate = useNavigate();
  // ------------------------------
  const token = sessionStorage.getItem("token")
  // ==============================
  const dispatch = useDispatch();
  const authData = token ? jwt(token)["user"][0] : null

  async function getTrades(status, tradeID) {
    try {

      let thisURL;
      if (typeof filter != "undefined" && filter != null && filter != "" && status)
        thisURL = `${URL}/admin/all-trades?status=${filter}`

      else if (typeof search != "undefined" && search != null && search != "" && tradeID)
        thisURL = `${URL}/admin/all-trades?search=${search}`

      else
        thisURL = `${URL}/admin/all-trades`

      await axios.get(thisURL)
        .then(res => {
          if (res.data.msg == "done") {
            setTrades(res.data.trades)
          }
          res.data.error && alert(res.data.error)
        })
        .catch(err => {
          console.log(err);
        })
    } catch (error) {
      console.log(error);
    }
  }

  function timeConverter(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
  }

  function fromWeitoEth(value) {
    return parseFloat(value) / 1000000000000000000
  }

  useEffect(() => {
    // ------------------------------
    if (!token || typeof token == "undefined") {
      navigate("/")
    }
    // ==============================
    else {
      getTrades()
    }
  }, [])



  return (
    <section className={darkMode ? "allTrades darkMode" : "allTrades"} >
      <div className="container">
        <div className="tradeTable">

          <div className="alltradesR1">

            <div className="userSearchMain pb-10-lg">
              <select name="" placeholder="Select Status" value={filter} onChange={(e) => setFilter(e.target.value)} >
                <option value="" >Select Status</option>
                <option value="requested">Requested</option>
                <option value="pending">Pending</option>
                <option value="dispute">Dispute</option>
                <option value="modified">Modified</option>
                <option value="fulfilled">Fulfilled</option>
                <option value="accept">Accepted</option>
                <option value="declined">Declined</option>
                <option value="deposit">Deposited</option>
                <option value="complete">Completed</option>
              </select>
              <button className="btn sBtn" onClick={() => getTrades(true, false)}>Filter by Status</button>
            </div>

            <div className="userSearchMain py-3">
              <input placeholder="Enter Trade ID" type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
              <button className="btn sBtn" onClick={() => getTrades(false, true)}>Search</button>
            </div>

          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col" className='cursorP'  >Trade ID</th>
                  <th scope="col">Buyer</th>
                  <th scope="col">Seller</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Status</th>
                  <th scope="col">Last Change</th>
                  <th scope="col">Turn</th>
                  <th scope="col">Details</th>
                </tr>
              </thead>
              <tbody className='tablecontents'>

                {
                  Trades?.map((trade, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">{trade?.tradeID}</th>
                        <td>{trade?.from?.username}</td>
                        <td>{trade?.to?.username}</td>
                        <td>{fromWeitoEth(trade?.crpytoAmount)} {trade?.currency}</td>
                        <td><div className={`status ${trade?.status}`} >{trade?.status == "accept" ? "accepted" : trade?.status == "complete" ? "completed" : trade?.status == "deposit" ? "deposited" : trade?.status}</div></td>
                        <td>{(trade?.last_change)}</td>
                        <td>{trade?.turn}</td>
                        <td><Link to={`/tradeDetail/${trade?._id}`} className='btn viewBtn' ><img src={view} alt="" /> View</Link></td>
                      </tr>
                    )
                  })
                }

              </tbody>
            </table>
          </div>


          {/* <div className='text-center'><button className='btn showMore'>Show more</button></div> */}

        </div>
      </div>
      <br />
      <br />
      <br />
    </section>
  )
}