import { applyMiddleware, createStore } from "redux";
import rootReducer from "./reducers/reducers";
import logger from 'redux-logger';



// function saveToLocalStorage(state) {
//     try {
//       const serialisedState = JSON.stringify(state);
//       sessionStorage.setItem("persistantState", serialisedState);
//     } catch (e) {
//       console.warn(e);
//     }
//   }
  
//   // load string from localStarage and convert into an Object
//   // invalid output must be undefined
//   function loadFromLocalStorage() {
//     try {
//       const serialisedState = sessionStorage.getItem("persistantState");
//       if (serialisedState === null) return undefined;
//       return JSON.parse(serialisedState);
//     } catch (e) {
//       console.warn(e);
//       return undefined;
//     }
//   }
  
//   // create our store from our rootReducers and use loadFromLocalStorage
//   // to overwrite any values that we already have saved
//   const store = createStore(rootReducer, loadFromLocalStorage(), applyMiddleware(logger));
  
//   // listen for store changes and use saveToLocalStorage to
//   // save them to localStorage
//   store.subscribe(() => saveToLocalStorage(store.getState()));
  
//   export default store;
  


//                                      redux devtool extension
const store = createStore( rootReducer, applyMiddleware(logger));

export default store;