import { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom";
import verified from "../images/verified.svg"
import star from "../images/star.png"
import idCard from "../images/idCard.png"
import user from "../images/user.png"
import { URL } from "../config";
import axios from "axios"
import moment from "moment"

export function UserDetail({ darkMode }) {
    const ID = useParams().id;
    const [user, setUser] = useState([])
    const [warn, setWarn] = useState(0)
    const [asABuyer, setAsBuyer] = useState([])
    const [asASeller, setAsSeller] = useState([])
    const [rating, set_rating] = useState(0)
    const [totalTrades, set_totalTrades] = useState(0)
    const [completeTrades, set_completeTrades] = useState(0)


    async function getData() {
        try {
            await axios.get(`${URL}/admin/kyc-page/${ID}`)
                .then(res => {
                    console.log(res.data);
                    setUser(res.data.user)
                    setWarn(res.data.user[0].warns)
                    setAsBuyer(res.data.asABuyer)
                    setAsSeller(res.data.asASeller)
                    set_rating(res.data.rating)
                    set_totalTrades(res.data.totalTrades)
                    set_completeTrades(res.data.completeTrades)

                })
                .catch(err => {
                    console.log(err);
                })

        } catch (error) {
            console.log(error);
        }
    }

    function dateConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = date + ', ' + month + ' ' + year;
        return time;
    }

    async function name(status) {
        await axios.get(`${URL}/admin/reply-kyc/${ID}/${status}`)
            .then(res => {
                if (res.data.msg == 'KYC updated') {
                    alert("Status updated")
                    getData()
                }

                res.data.error && alert(res.data.error)
            })
            .catch(err => {
                console.log(err);
            })
    }

    async function userStatusUpdate(state) {
        try {
            await axios.post(`${URL}/admin/update-isverified/${ID}/${state}`, {})
                .then(res => {
                    if (res.data.msg) {
                        setUser([{...user[0], isVerified: state }])
                        alert(res.data.msg)

                    }
                    res.data.error && alert(res.data.error)

                })
                .catch(err => {
                    console.log(err);
                })
        } catch (error) {
            console.log(error);
        }
    }

    async function WarnUser() {
        try {
            await axios.post(`${URL}/admin/warn-user/${ID}/`, {})
                .then(res => {
                    console.log(res.data);
                    if (res.data.msg) {
                        setWarn(res.data.user.warns)
                        alert(res.data.msg)

                        if (res.data.user.isVerified == false) {
                            setUser({ ...user, isVerified: res.data.user.isVerified })
                        }
                    }
                    res.data.info && alert(res.data.info)
                    res.data.error && alert(res.data.error)

                })
                .catch(err => {
                    console.log(err);
                })
        } catch (error) {
            console.log(error);
        }
    }

    async function RevokeWarning() {
        try {
            await axios.post(`${URL}/admin/revoke-warnings/${ID}/`, {})
                .then(res => {
                    console.log(res.data);
                    if (res.data.msg) {
                        setWarn(res.data.user.warns)
                        alert(res.data.msg)

                        if (res.data.user.isVerified == true) {
                            setUser({ ...user, isVerified: res.data.user.isVerified })
                        }
                    }
                    res.data.info && alert(res.data.info)
                    res.data.error && alert(res.data.error)

                })
                .catch(err => {
                    console.log(err);
                })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getData()

    }, [])



    return (
        <div className={darkMode ? "py-0 allTrades tradeDetailSec darkMode" : "py-0 allTrades tradeDetailSec"} >
            <div className="container">
                <div className="tradeTable">
                    <h1 >User Details</h1>

                    <div className="table-responsive">
                        <table className="table">
                            <thead>

                                <tr>
                                    <th scope="col" className='cursorP'  >USERNAME</th>
                                    <th scope="col">EMAIL</th>
                                    <th scope="col">WALLET</th>
                                    <th scope="col">RATING SCORE</th>
                                    <th scope="col">COUNTRY</th>
                                    <th scope="col">REGISTERED SINCE</th>
                                    <th scope="col">APPLICATION SENT</th>

                                </tr>
                            </thead>
                            <tbody className='pb-2'>

                                <tr>
                                    <th scope="row">{user[0]?.username}</th>
                                    <td>{user[0]?.email}</td>
                                    <td style={{ wordBreak: "break-all" }}>{user[0]?.wallet}</td>
                                    <td style={{ textAlign: "center" }}>{(user[0]?.rating)?.toFixed(1)}</td>
                                    <td style={{ textAlign: "center" }}>{typeof user[0]?.country != "undefined" ? user[0]?.country : "---"}</td>
                                    <td style={{ textAlign: "center" }}>{user[0]?.timestamp}</td>
                                    <td>{user[0]?.kycStatus}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-lg-6">
                        <div className="tradeDescription">
                            <div className="detailCard">
                                <h1>ID Document</h1>
                                <div className="form-group">
                                    <label>Nationality</label>
                                    <input required name="name" readOnly type="text" value={typeof user[0]?.country != "undefined" ? user[0]?.country : "---"} className="form-control" placeholder="germany" />
                                </div>
                                <label className="mt-3">Front Side:</label>
                                <div className="innerCard">
                                    <div className="idCard text-center">
                                        {
                                            user[0]?.kycStatus != "not-verified" ?
                                                <img className="img-fluid" src={`${URL}/fetchImage/${user[0]?.kyc?.IDCard?.filename}`} alt="idCard" />
                                                :
                                                <img className="img-fluid" width={250} src={idCard} alt="idCard" />
                                        }
                                    </div>
                                </div>
                                <label className="mt-3">Back Side:</label>
                                <div className="innerCard">
                                    <div className="idCard text-center">
                                        {
                                            user[0]?.kycStatus != "not-verified" ?
                                                <img className="img-fluid" src={`${URL}/fetchImage/${user[0]?.kyc?.IDCardBack?.filename}`} alt="idCard" />
                                                :
                                                <img className="img-fluid" width={250} src={idCard} alt="idCard" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="detailCard mb-0">
                            <h1>Selfie of User</h1>
                            <div className='tradeDetail mb-3'>
                                <p>These criteria must be fulfilled: <br /> 1. The Paper has to have this text: “EcrowTrade {user[0]?.username}” <br /> 2. His face has to be the person from the ID document.</p>
                            </div>
                            <div className="innerCard ">
                                <div className="idCard text-center">
                                    {
                                        user[0]?.kycStatus != "not-verified" ?
                                            <img className="img-fluid" src={`${URL}/fetchImage/${user[0]?.kyc?.photo?.filename}`} alt="idCard" />
                                            :
                                            <img className="img-fluid" width={250} src={idCard} alt="idCard" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="partnersProfileMain">
                    <div className="detailCard">
                        <h1>Your Partner’s Profile</h1>
                        <div className="ProfileMain">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="detailMain">
                                        <h3 className='name'>
                                            {user[0]?.username}
                                            <span>
                                                {user[0]?.kycStatus == "verified" && <img className='mb-1' src={verified} alt="verified" />}
                                            </span>
                                            <span style={{ color: user[0]?.kycStatus == "not-verified" ? "red" : user[0]?.kycStatus == "pending" ? "#fad16b" : "#10A549" }}>
                                                ( {(user[0]?.kycStatus)?.toUpperCase()} )
                                            </span>
                                        </h3>
                                        <p className='address'>{user[0]?.country != "" && user[0]?.country != null && typeof user[0]?.country != "undefined" ? (`From ${user[0]?.country} - `) : ""} Registered since {dateConverter(user[0]?.timestamp)}</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="totalTrade">
                                        <div className="content">
                                            <p>Total Trades</p>
                                            <h3>{totalTrades}</h3>
                                        </div>
                                        <div className="content">
                                            <p>Completed Trades</p>
                                            <h3>{completeTrades}</h3>
                                        </div>
                                        <div className="content">
                                            <p>Avg. Rating Score</p>
                                            <h3>{rating.toFixed(1)}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            (asABuyer.length > 0 || asASeller.length > 0) &&
                            <div className="reviewsMain mt-0">
                                <div className="row">
                                    {
                                        asABuyer.length > 0 &&
                                        <div className="col-lg-6">
                                            <h3>Reviews as Buyer</h3>
                                            {
                                                asABuyer?.map((review, i) => {
                                                    return (
                                                        <div className='review' key={i}>
                                                            <div className='profile'><span>{(review?.user?.username)?.substring(0, 1)}</span></div>
                                                            <div className="content">
                                                                <div className="name">
                                                                    <p>{review?.user?.username}</p>
                                                                    <span><img src={star} alt="rating" /> {review?.stars} </span>
                                                                </div>
                                                                <div className="country"> {review?.user.country} </div>
                                                                <p className='comment'>{review?.text}</p>
                                                                <p className='publish'>{moment((review?.timestamp)).fromNow()}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* <div className="seeMore">
                                                    <h4>+ See More</h4>
                                                </div> */}
                                        </div>
                                    }
                                    {
                                        asASeller.length > 0 &&
                                        <div className="col-lg-6">
                                            <h3>Reviews as Seller</h3>
                                            {
                                                asASeller?.map((review, i) => {
                                                    return (
                                                        <div className='review' key={i}>
                                                            <div className='profile'><span>{(review?.user?.username)?.substring(0, 1)}</span></div>
                                                            <div className="content">
                                                                <div className="name">
                                                                    <p>{review?.user?.username}</p>
                                                                    <span><img src={star} alt="rating" /> {review?.stars} </span>
                                                                </div>
                                                                <div className="country">{review?.user.country}</div>
                                                                <p className='comment'>{review?.text}</p>
                                                                <p className='publish'>{moment((review?.timestamp)).fromNow()}</p>
                                                            </div>
                                                        </div>

                                                    )
                                                })
                                            }
                                            {/* <div className="seeMore">
                                                    <h4>+ See More</h4>
                                                </div> */}
                                        </div>
                                    }

                                </div>
                            </div>
                        }

                    </div>
                </div>


                {
                    user[0]?.role !== "admin" &&
                    <>
                        {
                            user[0]?.kycStatus != "verified" ?
                                <div className="ActionMain">
                                    <div className="detailCard" style={{ textAlign: "center" }}>
                                        <h1 className="text-center">KYC Decision</h1>
                                        <button
                                            className='btn AcceptBtn'
                                            onClick={() => name("verified")}
                                        > Approve Application </button>
                                        <button
                                            className='btn danger'
                                            onClick={() => name("pending")}
                                            style={{
                                                backgroundColor: "#ed4a9e",
                                                boxShadow: "0 1px 0 rgb(14 14 41 / 40%)",
                                                borderRadius: "16px",
                                                color: "#fff",
                                                fontSize: '16px',
                                                fontWeight: "700",
                                                padding: "7px 19px",
                                                cursor: "pointer"
                                            }}>
                                            Pending Application
                                        </button>
                                        &nbsp;  &nbsp;
                                        <button
                                            className='btn btn-danger'
                                            onClick={() => name("not-verified")}
                                            style={{
                                                boxShadow: "0 1px 0 rgb(14 14 41 / 40%)",
                                                borderRadius: "16px",
                                                color: "#fff",
                                                fontSize: '16px',
                                                fontWeight: "700",
                                                padding: "7px 19px",
                                                cursor: "pointer"
                                            }}>
                                            Decline Application
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className="ActionMain">
                                    <div className="detailCard" style={{ textAlign: "center" }}>
                                        <h1 className="text-center">KYC Decision</h1>

                                        <div className="d-flex justify-content-center">
                                            <h1 style={{ color: "rgb(16, 165, 73)", fontWeight: 600, margin: 0 }}>User is KYC verified</h1>
                                            &nbsp;  &nbsp;
                                            <button
                                                className='btn danger'
                                                onClick={() => name("pending")}
                                                style={{
                                                    backgroundColor: "#ed4a9e",
                                                    boxShadow: "0 1px 0 rgb(14 14 41 / 40%)",
                                                    borderRadius: "16px",
                                                    color: "#fff",
                                                    fontSize: '16px',
                                                    fontWeight: "700",
                                                    padding: "7px 19px",
                                                    cursor: "pointer"
                                                }}>
                                                Annulate KYC Verification
                                            </button>
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className="ActionMain">
                            <div className="detailCard" style={{ textAlign: "center" }}>
                                <h1 className="text-center">Actions</h1>

                                <button
                                    className='warnBTN'
                                    onClick={() => WarnUser()}
                                > Warn User ({warn}/3) </button>

                                {
                                    user[0]?.isVerified ?
                                <button
                                    className='btn danger'
                                    onClick={() => userStatusUpdate(false)}
                                    style={{
                                        backgroundColor: "#ed4a9e",
                                        boxShadow: "0 1px 0 rgb(14 14 41 / 40%)",
                                        borderRadius: "16px",
                                        color: "#fff",
                                        fontSize: '16px',
                                        fontWeight: "700",
                                        padding: "7px 19px",
                                        cursor: "pointer"
                                    }}>
                                    Ban User
                                </button>
                            :
                                <button
                                    className='btn'
                                    onClick={() => userStatusUpdate(true)}
                                    style={{
                                        backgroundColor: "#3CD298",
                                        boxShadow: "0 1px 0 rgb(14 14 41 / 40%)",
                                        borderRadius: "16px",
                                        color: "#fff",
                                        fontSize: '16px',
                                        fontWeight: "700",
                                        padding: "7px 19px",
                                        cursor: "pointer"
                                    }}>
                                    Allow User
                                </button>

                                }

                                <button
                                    className='btn btn-info'
                                    onClick={() => RevokeWarning()}
                                    style={{
                                        // backgroundColor: "#ed4a9e",
                                        boxShadow: "0 1px 0 rgb(14 14 41 / 40%)",
                                        borderRadius: "16px",
                                        color: "#fff",
                                        fontSize: '16px',
                                        fontWeight: "700",
                                        padding: "7px 19px",
                                        cursor: "pointer",
                                        margin: "0px 10px"
                                    }}>
                                    Revoke Warning
                                </button>

                                <Link to={`/manage-token?wallet=${user[0]?.wallet}`}>
                                    <button
                                        className='btn btn-danger'
                                        style={{
                                            boxShadow: "0 1px 0 rgb(14 14 41 / 40%)",
                                            borderRadius: "16px",
                                            color: "#fff",
                                            fontSize: '16px',
                                            fontWeight: "700",
                                            padding: "7px 19px",
                                            cursor: "pointer"
                                        }}>
                                        Freeze his ESCW
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </>
                }

            </div>
        </div>
    )
}