import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../apis";
import NewSlider from "./SlickSlider/newSlider"
import CURRENCY from "./getCurrencies"
import "./newpages.css";
import stripTags from 'striptags';

import bnb from "../images/BNBLogo.png";
import btc from "../images/BTCLogo.png";
import eth from "../images/ETHLogo.png";
import usdt from "../images/USDTLogo.png";
import escw from "../images/matic-token.png";
import matic from "../images/matic-token.png";
import "../Market.css"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";



const AdminOffer = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({});
    const [offer, setOffer] = useState([])
    const handleDate = (value) => {
        setFormData({ ...formData, 'date': value })
    };

    async function getOffer() {
        if (typeof id !== "undefined" && id.length == 24) {
            const response = await api.getofferByID({ _id: id })
            if (response.success) setOffer(response.message)
            console.log("response", response);
        }
    }

    const updateofferstatus = async (status) => {

        try {
            console.log("helo");
            if (typeof id !== "undefined" && id.length == 24) {

                const response = await api.updateofferstatus({ _id: id, status: status })

                if (response.success) alert("Offer updated")

                if (!response.success) alert(response.message)
            }
            getOffer();
        }
        catch (e) {
            console.log(e);
        }

    }

    const [Variants, setVariants] = useState([])

    const getvariants = async () => {
        try {
            const payload = {
                productID: id
            }

            const response = await api.getVariants(payload)
            console.log(response);
            setVariants(response.message)
            console.log("variants", Variants);

        }

        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getOffer()
        getvariants()
    }, [])

    function dateConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = date + ', ' + month + ' ' + year;
        return time;
    }



    // GRAPH
    const [graphData, setGraphData] = useState([]);
    const [duration, setDuration] = useState("week-to-date")
    const [type, setType] = useState({
        type: 1,
        name: "Net Revenue"
    })

    const handletradesChange = (abc) => {
        setDuration(abc);
        getGraphData(abc)
    };

    const getGraphData = async (abc) => {
        try {
            const payload = {
                time: abc,
                offerid: id,
                type: type.type
            }
            console.log("payload", payload);

            const response = await api.GraphData(payload)
            console.log("response");
            console.log(response);

            let format = [];
            if (type.type == 1) {
                format = response.map((e, i) => {
                    return {
                        name: "Date : " + e[0],
                        "Net Revenue": e[1]
                    }
                })
            }
            else if (type.type == 2) {
                format = response.map((e, i) => {
                    return {
                        name: "Date : " + e[0],
                        "Orders": e[1]
                    }
                })
            }
            else if (type.type == 3) {
                format = response.map((e, i) => {
                    return {
                        name: "Date : " + e[0],
                        "Conversion Rate": e[1]
                    }
                })
            }
            else if (type.type == 4) {
                format = response.map((e, i) => {
                    return {
                        name: "Date : " + e[0],
                        "Views": e[1]
                    }
                })
            }
            else if (type.type == 5) {
                format = response.map((e, i) => {
                    return {
                        name: "Date : " + e[0],
                        "Lost Disputes": e[1]
                    }
                })
            }
            console.log(format);
            setGraphData(format)
        }
        catch (e) {
            console.log(e);
        }
    }

    const handletypechange = (event, val) => {
        setType({
            type: val,
            name: (val == 1 ? "Net Revenue" : val == 2 ? "Orders" : val == 3 ? "Views" : val == 4 ? "Conversion Rate" : val == 5 ? "Lost Disputes" : "Net Revenue")
        });

    };

    useEffect(() => {
        if (typeof type.type != "undefined" && type.type != null && type.type != "") {
            getGraphData(duration)
        }

    }, [type.type])



    return (
        <div className="adminoffer-container">
            <div className="container">
                <div className="row justify-space-between ">
                    <div className="mt-5 col-12 col-md-12 col-lg-6 mb-5">
                        <div className="details h-100 w-100">
                            <h1 className="text-center" style={{ marginBottom: '40px' }}>Overview</h1>
                            <div className="detail-info mb-3">
                                <span className="detail-left">Offer ID</span>
                                <span className="detail-right">{offer?._id}</span>
                            </div>
                            <div className="detail-info mb-3">
                                <span className="detail-left">Status</span>
                                <span className="tradeSec p-0 bg-transparent">
                                    <span className="tradeTable" style={{ boxShadow: "none" }}>
                                        {
                                            Date.now() < parseInt(offer?.expiry) ?
                                                <span className={`detail-right status ${offer?.status == "active" ? "accept" : offer?.status == "pending" ? "pending" : "dispute"} `}>
                                                    {offer?.status}
                                                </span>
                                                :
                                                <span className={`detail-right status dispute`}>
                                                    expired
                                                </span>
                                        }
                                    </span>
                                </span>
                            </div>
                            <div className="detail-info mb-3">
                                <span className="detail-left">Title</span>
                                <span className="detail-right">{offer?.title}</span>
                            </div>
                            <div className="detail-info mb-3">
                                <span className="detail-left">Vendor Username</span>
                                <span className="detail-right">{offer?.userId?.username}</span>
                            </div>
                            <div className="detail-info mb-3">
                                <span className="detail-left">Product Type</span>
                                <span className="detail-right">{offer?.productType}</span>
                            </div>
                            <div className="detail-info mb-3">
                                <span className="detail-left">Delivery time</span>
                                <span className="detail-right">{offer?.deliveryTime}</span>
                            </div>
                            <div className="detail-info mb-3">
                                <span className="detail-left">Available quantity</span>
                                <span className="detail-right">{offer?.availableQuantity == null ? "NA" : offer?.availableQuantity}</span>
                            </div>
                            <div className="detail-info mb-3">
                                <span className="detail-left">Auto-Accept</span>
                                <span className="detail-right">{offer?.autoaccept ? "yes" : "no"}</span>
                            </div>
                            <div className="detail-info mb-3">
                                <span className="detail-left">Created on</span>
                                <span className="detail-right">
                                    {dateConverter(offer?.timestamp)}
                                </span>
                            </div>
                            <div className="detail-info mb-3">
                                <span className="detail-left">Expires on</span>
                                <span className="detail-right">
                                    {dateConverter(offer?.expiry)}
                                </span>
                            </div>
                            <div className="detail-info mb-3">
                                <span className="detail-left">Rating</span>
                                <span className="detail-right">{offer?.rating == null ? 0 : offer?.rating}</span>
                            </div>

                            {/* <div className="detail-info mb-3">
                                <span className="detail-left text-danger">Avg. Review score</span>
                                <span className="detail-right">{offer?.rating}</span>
                            </div> */}

                            <div className="detail-info mb-3">
                                <span className="detail-left">Sales made</span>
                                <span className="detail-right">{offer?.salesMade}</span>
                            </div>

                            <div className="detail-info mb-3">
                                <span className="detail-left">Total Revenue</span>
                                <span className="detail-right">{offer?.totalRevenue} USD</span>
                            </div>
                            <div className="detail-info mb-3">
                                <span className="detail-left">Placement</span>
                                <span className="detail-right">{offer?.planid?.title}</span>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-5 col-12 col-md-12 col-lg-6 mb-5">
                        <div className="analyticMain">
                            <div className="offer-analytics-section py-5">
                                <div className="px-3 px-sm-5 mb-4 h-100">
                                    <div>
                                        <h1 className="offer-title text-center mb-4">Analytics</h1>
                                    </div>
                                    <div className="offer-serach-boxes row">
                                        <div className="offer col-12  ">
                                            <label htmlFor="" className="offer-label">
                                                Time span
                                            </label>
                                            {/* <input type="text" className="w-100" placeholder="1 day" disabled /> */}
                                            <div className="dropdown customSelect">
                                                <button className="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {duration != "" ? duration : 'Select Date'}
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {/* <a className="dropdown-item" value="today" onClick={() => handletradesChange("today")}>Today</a> */}
                                                    {/* <a className="dropdown-item" value="yesterday" onClick={() => handletradesChange("yesterday")}> Yesterday </a> */}
                                                    <a className="dropdown-item" onClick={() => handletradesChange("week-to-date")}> Week to Date   </a>
                                                    <a className="dropdown-item" onClick={() => handletradesChange("last-week")}>    Last Week      </a>
                                                    <a className="dropdown-item" onClick={() => handletradesChange("month-to-date")} >Month to Date </a>
                                                    <a className="dropdown-item" onClick={() => handletradesChange("last-month")}>   Last Month     </a>
                                                    <a className="dropdown-item" onClick={() => handletradesChange("last-3-month")}> Last 3 Months  </a>
                                                    <a className="dropdown-item" onClick={() => handletradesChange("last-6-month")}>Last 6 Months</a>
                                                    {/* <a className="dropdown-item" onClick={() => handletradesChange("year-to-date")}>Year to date</a> */}
                                                    {/* <a className="dropdown-item" onClick={() => handletradesChange("last-year")}>Last year</a> */}                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="analyticsFilter">
                                    <p className="title mr-2">
                                        Show Graph for:
                                    </p>
                                    <div className="list">
                                        <div className={type?.name == 'Net Revenue' ? "item active" : "item"} onClick={(e) => handletypechange(e, 1)}>Net Revenue</div>
                                        <div className={type?.name == 'Orders' ? "item active" : "item"} onClick={(e) => handletypechange(e, 2)}>Orders</div>
                                        {/* <div className={type?.name == 'Views' ? "item active" : "item"} onClick={(e) => handletypechange(e, 3)}>Views</div> */}
                                        {/* <div className={type?.name == 'Conversion Rate' ? "item active" : "item"} onClick={(e) => handletypechange(e, 4)}>Conversion Rate</div> */}
                                        <div className={type?.name == 'Lost Disputes' ? "item active" : "item"} onClick={(e) => handletypechange(e, 5)}>Lost Disputes</div>
                                    </div>
                                </div>
                                <div className="chartMain">
                                    {
                                        graphData.length > 0 ?
                                            <ResponsiveContainer width="100%" height="100%">
                                                <LineChart width={500} height={300} data={graphData} margin={{ top: 5, right: 30, left: 20, bottom: 5, }} >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line type="monotone" dataKey={type.name} stroke="#8884d8" activeDot={{ r: 8 }} />
                                                </LineChart>
                                            </ResponsiveContainer>
                                            :
                                            <h2>Graph not available</h2>
                                    }
                                </div>
                                {/* <div className=" px-3 px-sm-5 mt-3">
                                    <div className="detail-info mb-2">
                                        <span className="detail-left text-danger">Sales</span>
                                        <span className="detail-right">bnb-1234567</span>
                                    </div>
                                    <div className="detail-info mb-2">
                                        <span className="detail-left text-danger">Revenue</span>
                                        <span className="detail-right">bnb-1234567</span>
                                    </div>
                                    <div className="detail-info mb-2">
                                        <span className="detail-left text-danger">Views</span>
                                        <span className="detail-right">bnb-1234567</span>
                                    </div>
                                    <div className="detail-info mb-2">
                                        <span className="detail-left text-danger">Conversion Rate</span>
                                        <span className="detail-right">bnb-1234567</span>
                                    </div>
                                    <div className="detail-info mb-2">
                                        <span className="detail-left text-danger">Total Customers</span>
                                        <span className="detail-right">bnb-1234567</span>
                                    </div>
                                    <div className="detail-info mb-2">
                                        <span className="detail-left text-danger">Disputes created</span>
                                        <span className="detail-right">bnb-1234567</span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-space-center ">
                    <div className="col-12 col-md-12 col-lg-6 mb-5">
                        <div className="adminoffer-description  w-100 h-100">
                            <h1 className="text-center mb-3">Description</h1>
                            <div className="textarea-section mb-2"
                                dangerouslySetInnerHTML={{ __html: offer?.description }}
                            >
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 mb-5">
                        <div className="images text-center h-100 w-100">
                            <h1 className="mb-3">Images</h1>
                            <div className="images-slider mb-0">
                                <NewSlider gallery={offer?.gallery} smallSlidesWidth={"120px"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-space-center">
                    <div className="col-12 col-md-12 col-lg-6 mb-5">
                        <div className="option-pricing w-100 h-100">
                            <h1 className="text-center mb-3">Options & Pricing</h1>
                            {
                                Variants?.length > 0 && (

                                    <div className=" p-sm-4 p-3 mb-3 d-flex flex-column">
                                        <h4 className="mb-3" style={{ fontWeight: 600 }}>Variants</h4>
                                        {
                                            Variants.map((item, key) => (
                                                <button key={key} className=
                                                    "info-btn mb-2 d-flex justify-content-between"
                                                // onClick={() => setProductData({ ...productData, size: item?.name, price: item?.price, stock: item?.onHand })}
                                                >
                                                    <span>{item?.name}</span>
                                                    <span>+ $ {item?.price}</span>
                                                </button>
                                            ))
                                        }
                                    </div>
                                )
                            }
                            {/* <p className="op-pricing mb-2">Size</p> */}
                            {/* <div className="option-pricing-btns mb-2">
                                <button className="option-btn  ">Variation A</button>
                                <button className="option-btn ">Variation B</button>
                                <button className="option-btn active">Variation C</button>
                            </div> */}
                            {/* <p className="op-pricing my-4">Price for variation: <span>$ 100</span></p> */}


                            <div className="currency mb-3">
                                <span className="fw-500">Accepted currencies:</span>
                                <div className="currency-section d-flex mt-2 mx-2">
                                    {typeof offer?.currencies !== "undefined" && (offer?.currencies).length > 0 &&
                                        (offer?.currencies).map((item, i) => (
                                            <div key={i} className="mx-1">
                                                <img src={CURRENCY(item?.currency)} alt="Currency" className={"mx-1"} />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 mb-5">
                        <div className="admin-review h-100 w-100">
                            <h1 className="text-center mb-3">Change status</h1>
                            {
                                offer?.status == "pending" ?
                                    <div className="review-btns text-center" style={{ height: "80%", display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: "20px" }}>
                                        <button className="approve-btn me-3" disabled={offer?.status == "pending" ? false : true} onClick={() => updateofferstatus("un-paid")}>Approve Offer</button>
                                        <button className="decline-btn" disabled={offer?.status == "pending" ? false : true} onClick={() => updateofferstatus("reject")}>Reject Offer</button>
                                    </div>
                                    :

                                    <div className="review-btns text-center" style={{ height: "80%", display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: "20px" }}>
                                        <button className="approve-btn me-3" onClick={() => updateofferstatus("pending")}>Pending</button>
                                        {
                                            offer?.status == "active" ?
                                                <button className="approve-btn me-3" onClick={() => updateofferstatus("un-active")}>
                                                    Un-active
                                                </button>
                                                :
                                                <button className="approve-btn me-3" onClick={() => updateofferstatus("active")}>
                                                    Active
                                                </button>
                                        }
                                        <button className="decline-btn me-3" onClick={() => updateofferstatus("reject")} >Reject Offer</button>
                                        <button className="approve-btn me-3" onClick={() => updateofferstatus("un-paid")}>Un-paid</button>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminOffer;
