import bnb from "../images/market/BNB.svg";
import btc from "../images/market/BTC.svg";
import busd from "../images/market/BTC.svg";
import eth from "../images/market/ETH.svg";
import usdt from "../images/market/USDT.svg";
import escw from "../images/market/escw.svg";
import matic from "../images/market/MATIC.svg";


export default function CURRENCY(name) {

    const currency = name?.toLowerCase();

    if (currency == "bnb") {
        return bnb
    }
    if (currency == "btc") {
        return btc
    }
    if (currency == "busd") {
        return busd
    }
    if (currency == "usdt") {
        return usdt
    }
    if (currency == "eth") {
        return eth
    }
    if (currency == "escw") {
        return escw
    }
    if (currency == "matic") {
        return matic
    }

    return bnb
}