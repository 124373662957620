import { useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { URL } from "../config";
import axios from "axios"




function Login({ darkMode, logingHandler }) {

    return (
        <section className={darkMode ? "allTrades darkMode" : "allTrades"}>
            <div className="container" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "85vh" }}>
                <div className="row" style={{ backgroundColor: "white", borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", margin: "0px 10px", padding: "25px", maxWidth: "400px", maxHeight: "400px", boxShadow: "11px 7px 18px -2px rgba(148,148,148,0.75)" }}>
                    <div className="col-12" style={{ borderBottom: "1px solid gray", marginBottom: "10px" }}>
                        <h2 className="nav-link" style={{ textAlign: "center" }}>Login to View All Resources</h2>
                    </div>
                    <div className="col-12">

                        <form>
                            {/* <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                <input type="email" className="form-control" placeholder="Enter email" aria-describedby="emailHelp" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                <input type="password" className="form-control" placeholder="Enter password" />
                            </div> */}
                            <center>
                                <button type="button" className="btn btn-outline-primary px-5 py-2" onClick={() => logingHandler()}>Login</button>

                            </center>
                        </form>
                    </div>

                </div>

            </div>


        </section>

    );
}

export default Login;