import { useEffect, useState } from "react";
// import AOffer from "../component-market/AllOffers/AllOffers";
// import { BsFillPatchExclamationFill } from "react-icons/bs";

import view from '../images/view.svg'
import loader from '../images/loader.gif'

import "../Market.css"
import api from "../apis/index";


const AllOffer = ({ darkMode }) => {
    const [offers, setOffers] = useState([]);
    const [status, setStatus] = useState({ status: "" })
    const [offersLength, setOffersLength] = useState();
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        getoffers();
        getoffersLength();

    }, [])

    const getoffersLength = async () => {
        try {
            const response = await api.getoffersLength();
            const number = parseInt(response?.message)

            setOffersLength(number)
            console.log(response?.message);
            console.log("offer length", response?.message);
        }
        catch (e) {
            console.log(e);
        }
    }

    const getoffers = async () => {
        try {
            const payload = {
                skip: offers.length
            }

            const response = await api.getoffers(payload);

            setOffers([...offers, ...response.message])
            console.log(response);
        }
        catch (e) {
            console.log(e);
        }
    }

    const getoffersbystatus = async (event) => {
        try {
            if (event.target.value === "reset") {

                const response = await api.getoffers();
                setOffers(response.message)
            }
            else {
                const payload = {
                    status: event.target.value
                }

                const response = await api.getoffersbystatus(payload);

                setOffers(response.message)
                console.log(response);
            }

        }
        catch (e) {
            console.log(e);
        }
    }

    function dateConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = date + ', ' + month + ' ' + year;
        return time;
    }

    return (
        <div className={darkMode ? 'tradeSec  darkMode' : 'tradeSec '} style={{ padding: "50px auto" }} >

            {
                showLoader &&
                <div className='loader'>
                    <img src={loader} alt="loader" />
                </div>

            }

            <div className="container">
                <div className="tradeTable mt-0">
                    <h1>All Offers</h1>

                    <div class="alltradesR1">
                        <div class="userSearchMain pb-10-lg">

                            <div className="dropdown customSelect">
                                {/* <button className="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                                {/* Select Status */}
                                <select onChange={(e) => getoffersbystatus(e)} className="" name="" id="" >

                                    <option value="" disabled selected>Select Status</option>

                                    <option value="active">
                                        Active
                                    </option>
                                    <option value="pending">
                                        Pending
                                    </option>
                                    <option value="un-active">
                                        Un-active
                                    </option>

                                    <option value="reset">
                                        Reset
                                    </option>
                                </select>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">


                                </div>
                            </div>
                            {/* <button className="btn sBtn">Filter by Status</button> */}
                        </div>
                        <div className="userSearchMain py-3">
                            <input placeholder="Enter Trade ID" type="text" value="" />
                            <button className="btn sBtn">Search</button>
                        </div>
                    </div>



                    <div className="table-responsive">
                        <table className="table mb-2">
                            <thead>

                                <tr>
                                    <th scope="col" className='cursorP tbh'>OFFER ID</th>
                                    <th scope="col">OFFER TITLE</th>
                                    <th scope="col" className='cursorP tbh'>VENDOR</th>
                                    <th scope="col">STATUS</th>
                                    <th scope="col" className='cursorP tbh'>EXPIRES ON</th>
                                    <th scope="col">PLAN</th>
                                    <th scope="col">SALES</th>
                                    <th scope="col">REVENUE</th>
                                    <th scope="col">RATING SCORE</th>
                                    <th scope="col">DETAILS</th>
                                </tr>
                            </thead>
                            <tbody className='pb-2'>
                                {
                                    offers?.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <th scope="row">{item?._id}</th>
                                                <td>{item?.title}</td>
                                                <td>VENDOR</td>
                                                {/* <td><div className={`status ${item?.status.toLowerCase()}`} >{item?.status == "accept" ? "accepted" : item?.status == "deposit" ? "deposited" : item?.status == "complete" ? "completed" : item?.status}</div></td> */}
                                                <td><div className={`status ${item?.status == "active" ? "accept" : "dispute"} `} >{item?.status}</div></td>


                                                <td>{dateConverter(item?.expiry)}</td>
                                                <td>{item?.planid?.title}</td>
                                                <td>SALES</td>
                                                <td>REVENUE</td>
                                                <td>RATING</td>
                                                {/* <td><a href={`/trade/tradeDetail/${item?._id + Date.now()}`} className='btn viewBtn' ><BsFillPatchExclamationFill /> View</a></td> */}
                                                <td><a href={`/trade/tradeDetail/${item?._id + Date.now()}`} className='btn viewBtn' ><img src={view} alt="" /> View</a></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="d-flex align-items-center justify-center justify-content-center">
                        {
                            offersLength !== offers.length && (
                                <button onClick={() => getoffers()} className="cards-btn m-5">Load More</button>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllOffer;
