import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
// import { baseUrl } from "./config";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SlickSlider.css"
import { NODE_URL_RPC, URL } from "../../config";


export default function NewSlider({ gallery, smallSlidesWidth }) {
    // const imgs = [
    //     "https://bearxlabs.com/static/media/1.8d0e5272.gif",
    //     "https://bearxlabs.com/static/media/1.e4adf322.png",
    //     "https://bearxlabs.com/static/media/2.9bbedead.png",
    //     "https://bearxlabs.com/static/media/3.98e1c628.png",
    //     "https://bearxlabs.com/static/media/2.9bbedead.png",
    // ]


    const imgs = gallery || []
    console.log("images", imgs);

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    function name(e) {

    }

    return (
        < div className="" >
            <div>
                {
                    imgs.length > 1 ?
                        <>
                            <Slider
                                asNavFor={nav2}
                                ref={(slider1) => setNav1(slider1)}
                                slidesToShow={1}
                                slidesToScroll={1}
                                arrows={true}
                                fade={false}
                                adaptiveHeight={true}
                                infinite={true}
                                useTransform={true}
                                autoplay={true}
                                speed={2000}
                                autoplaySpeed={2000}
                                cssEase='cubic-bezier(0.77, 0, 0.18'
                            >
                                {
                                    imgs.length > 0 && imgs.map((item, i) => {
                                        return (
                                            <div className="slider-img" key={i}>
                                                <img className="" src={`${URL}/fetchOfferFiles/${item}`} />
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                            <Slider
                                   asNavFor={nav1}
                                   ref={(slider2) => setNav2(slider2)}
                                   // slidesToShow={3}
                                   // swipeToSlide={true}
                                   focusOnSelect={true}
                                   slidesToShow={3}
                                   slidesToScroll={3}
                                   dots={false}
                                   // focusOnSelect = {false}
                                   infinite={true}
                                   responsive={[{
                                       breakpoint: 1024,
                                       settings: {
                                           slidesToShow: 3,
                                           slidesToScroll: 3,
                                       }
                                   }, {
                                       breakpoint: 640,
                                       settings: {
                                           slidesToShow: 3,
                                           slidesToScroll: 3,
                                       }
                                   }, {
                                       breakpoint: 420,
                                       settings: {
                                           slidesToShow: 2,
                                           slidesToScroll: 2,
                                       }
                                   }]}
                            >
                                {
                                    imgs.length > 0 && imgs.map((item, i) => {
                                        console.log("item render => ", imgs);
                                        return (
                                            <div className="slider-img slides" key={i}>
                                                <img className="" src={`${URL}/fetchOfferFiles/${item}`} style={{ maxWidth: `${smallSlidesWidth ? smallSlidesWidth : "700px"}` }} />
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </>
                        :
                        <Slider
                            asNavFor={nav2}
                            ref={(slider1) => setNav1(slider1)}
                            slidesToShow={1}
                            slidesToScroll={1}
                            arrows={true}
                            fade={false}
                            adaptiveHeight={true}
                            infinite={true}
                            useTransform={true}
                            autoplay={true}
                            speed={2000}
                            autoplaySpeed={2000}
                            cssEase='cubic-bezier(0.77, 0, 0.18'
                        >
                            {
                                imgs.length > 0 && imgs.map((item, i) => {
                                    return (
                                        <div className="slider-img" key={i}>
                                            <img className="" src={`${URL}/fetchOfferFiles/${item}`} />
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                }
            </div>
        </div >
    );
}