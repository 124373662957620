import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { URL } from '../config';
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, ProgressBar } from 'react-bootstrap'
import star from "../images/star.png"
import startImg from "../images/startImg.png"
import loaderImg from '../images/loader.gif'
import attachment from '../images/attachment.svg'
import loader from '../images/loader.gif'
import fileLogo from "../images/fileLogo.svg"
import send from '../images/send.svg'
import verified from '../images/verified.svg'
import { useSelector, useDispatch } from "react-redux";
import { login, chatList, currentChat, friendName, emptyFriendName } from '../redux/actions/actions';

// ------------------------------
import jwt from "jwt-decode";
import Chat from './chat';
// ==============================

function TradeDetail({ socket, darkMode, logingHandler, startTrade_ETH, handleDisputeETH, handleDisputeBNB, handleDisputeMATIC }) {
    // ------------------------------
    const token = sessionStorage.getItem("token")
    // ==============================
    const bottomRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ID = useParams().id;
    const authData = token ? jwt(token)["user"][0] : null
    // const myFriendName = useSelector((state) => state.friendNameReducer)
    const [desc, setDesc] = useState("")
    const [buyBtn, setBuyBtn] = useState("buy");
    const [thisTrade, setThisTrade] = useState({});
    const [BuyerTradeCount, setBuyerTradeCount] = useState({ rating: 0, totalTrades: 0, completeTrades: 0 });
    const [SellerTradeCount, setSellerTradeCount] = useState({ rating: 0, totalTrades: 0, completeTrades: 0 });
    const [rating, setRating] = useState(0);
    const [asASellerReviews, setSellerReviews] = useState([]);
    const [asABuyerReviews, setBuyerReviews] = useState([]);
    const [reviewThisTrade, setReviewThisTrade] = useState("done");
    const [showWithdrawBTN, setShowWithdrawBTN] = useState(false);

    const [showLoader, setShowLoader] = useState(false);

    // notification setting
    const notify = (response, types) =>
        types(response, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    async function getTradeData() {
        // console.log("---------------123--------------------------");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        await axios.get(`${URL}/user/my-trade-details/${ID}`, config)
            .then(async res => {
                //   console.log("res.data", res.data.trade);
                if ((res.data.trade).length > 0) {
                    console.log(res.data);
                    setThisTrade(res.data.trade[0]);

                    // // Checking to show Withdraw BTN 
                    // if (res.data.trade[0]?.status == "complete") {
                    //     if (await getState_ETH(res.data.trade[0]?.tradeID, res.data.trade[0]?.currency) == "2" && res.data.trade[0]?.to?._id == authData?._id) {
                    //         // alert(true)
                    //         setShowWithdrawBTN(true);
                    //     }
                    //     // else alert(false);

                    // }

                    const TO = res.data.trade[0]?.to
                    const FROM = res.data.trade[0]?.from
                    // dispatch(friendName(res.data.trade[0]?.from?.username == authData?.username ?
                    //     {
                    //         id: TO._id,
                    //         wallet: TO.wallet,
                    //         email: TO.email,
                    //         username: TO.username,
                    //         isVerified: TO.isVerified,
                    //         timestamp: TO.timestamp,
                    //         rating: TO.rating,
                    //         rateCount: TO.rateCount,
                    //         kycStatus: TO.kycStatus,
                    //     }
                    //     :
                    //     {
                    //         id: FROM._id,
                    //         wallet: FROM.wallet,
                    //         email: FROM.email,
                    //         username: FROM.username,
                    //         isVerified: FROM.isVerified,
                    //         timestamp: FROM.timestamp,
                    //         rating: FROM.rating,
                    //         rateCount: FROM.rateCount,
                    //         kycStatus: FROM.kycStatus,
                    //     }
                    // ))
                    // startChat(FROM, TO)

                    // buyer
                    getTradeCount(FROM._id, "buyer")
                    // seller
                    getTradeCount(TO._id, "seller")
                    getReviews(FROM._id, TO._id)
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status == 401) {
                    sessionStorage.clear();
                    notify("Unauthorized user or session expired", toast.error)
                    navigate("/")
                }
            })
    }
    async function getTradeCount(id, userType) {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        // console.log("myFriendName ----", myFriendName);
        await axios.get(`${URL}/user/user-trade-counts/${id}`, config)
            .then(res => {
                console.log(res.data.totalTrades);
                console.log(res.data.completeTrades);
                console.log(res.data.rating);
                if (userType == "buyer") {
                    setBuyerTradeCount({ rating: res.data.rating, totalTrades: res.data.totalTrades, completeTrades: res.data.completeTrades });
                }
                if (userType == "seller") {
                    setSellerTradeCount({ rating: res.data.rating, totalTrades: res.data.totalTrades, completeTrades: res.data.completeTrades });
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status == 401) {
                    sessionStorage.clear();
                    notify("Unauthorized user or session expired", toast.error)
                    navigate("/")
                }
            })
    }
    async function getReviews(buyer, seller) {
        console.log("-----------------------------------------");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        await axios.get(`${URL}/admin/get-review/${buyer}/${seller}`, config)
            .then(res => {
                console.log(res.data.asASeller);
                console.log(res.data.asABuyer);
                setSellerReviews(res.data.asASeller)
                setBuyerReviews(res.data.asABuyer)
            })
            .catch(err => {
                console.log(err);
                if (err.response.status == 401) {
                    sessionStorage.clear();
                    notify("Unauthorized user or session expired", toast.error)
                    navigate("/")
                }
            })
        console.log("==========================================");
    }


    // ----------------------------------------------------------------
    const myCurrentChat = [] // useSelector((state) => state.currentChatReducer)
    const [currentMessage, setCurrentMessage] = useState("");
    const [messageList, setMessageList] = useState([]);
    const [fileUplaodProgress, setFileUplaodProgress] = useState("");
    const [showFileProgressBar, setShowhowFileProgressBar] = useState(false)
    const [userIsOnline, setUserIsOnline] = useState(false);
    const [friendIsOnline, setFriendIsOnline] = useState(false);
    const [temp, setTemp] = useState(false);
    const [img, setImg] = useState("");
    let msgOffset = 0;

    /** ============================  **/
    // async function startChat(FROM, TO) {
    //     await axios.post(`${URL}/chat/join-chat`, {
    //         username: FROM?._id,
    //         friendID: TO?._id,
    //         tradeID: ID
    //     })
    //         .then(res => {
    //             console.log("res.data start");
    //             console.log(res.data);
    //             if (res.data.msg == 'chatDataFound') {
    //                 dispatch(currentChat(res.data.chatData))

    //                 if (typeof res?.data?.chat != "undefined" && (res?.data?.chat).length > 0) {
    //                     const strArr1 = res?.data?.chat;
    //                     const descArr = strArr1.sort().reverse();
    //                     console.log(descArr); // 👉️ ['z', 'f', 'c', 'a']

    //                     setMessageList((list) => [...list, ...(descArr)]);
    //                     msgOffset += (res?.data?.chat).length

    //                 }

    //                 bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    //                 var messageBody = document.querySelector('.chatDiv');
    //                 messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;

    //             }
    //             res.data.error && alert(res.data.error)

    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })

    // }
    /** ============================  **/
    // function fileUploaded(e) {
    //     try {
    //         console.log(e.target.files);

    //         if (e.target.files[0]) {
    //             const formData = new FormData();

    //             for (const file of e.target.files) {
    //                 formData.append('imgs', file) // appending every file to formdata
    //             }

    //             formData.append("roomID", myCurrentChat._id);
    //             formData.append("author", authData?.username);
    //             formData.append("tradeID", (ID).substring(0, 24));
    //             formData.append("type", "img");

    //             const Config = {
    //                 headers: {
    //                     'content-type': 'multipart/form-data'
    //                 }
    //             };
    //             console.log(formData);
    //             console.log(Config);

    //             setShowhowFileProgressBar(true)
    //             axios.post(`${URL}/chat/save-file`, formData, {
    //                 onUploadProgress: progressEvent => {
    //                     console.log(`upload progress: ${Math.round(progressEvent.loaded / progressEvent.total * 100)}%`);

    //                     setFileUplaodProgress(Math.round(progressEvent.loaded / progressEvent.total * 100))
    //                     if (Math.round(progressEvent.loaded / progressEvent.total * 100) == 100) {
    //                         setShowhowFileProgressBar(false)
    //                         setFileUplaodProgress(0)
    //                     }
    //                 }
    //             })
    //                 .then(async response => {
    //                     console.log("response");
    //                     console.log(response);
    //                 })
    //         }

    //     } catch (error) {
    //         console.log(error);
    //         setShowhowFileProgressBar(false)
    //     }
    // }
    // const sendMessage = async () => {
    //     console.log("myCurrentChat", myCurrentChat);
    //     if (currentMessage !== "") {
    //         const messageData = {
    //             roomID: myCurrentChat._id,
    //             author: authData?.username,
    //             msg: currentMessage,
    //             type: "text",
    //             tradeID: (ID).substring(0, 24),
    //             timestamp: Date.now()
    //         };

    //         await socket.emit("send_message", messageData);
    //         setCurrentMessage("")
    //     }
    // };
    // function findSellerBuyer() {
    //     if (thisTrade?.offerType == "buyer" && thisTrade?.from?.username == authData?.username) {
    //         // console.log("buyer")
    //         return "buyer"

    //     }
    //     if (thisTrade?.offerType == "seller" && thisTrade?.from?.username == authData?.username) {
    //         // console.log("buyer")
    //         return "buyer"

    //     } else {
    //         // console.log("seller")
    //         return "seller"

    //     }
    // }
    async function updateTradeStatus(Status1) {
        try {
            console.log("-----------------------------------------");
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            if (Status1 == "dispute") {
                createDisputeDB(Status1)
            } else {
                updateInDB(Status1)
            }

            async function createDisputeDB(Status1) {
                // await axios.post(`${URL}/user/create-dispute/${thisTrade._id}/${authData?._id}`, {
                //     status: Status1,
                //     userID: authData?._id,
                //     username: authData?.username,
                //     msg: authData?.username + " has started dispute"

                // }, config)
                //     .then(res => {
                //         console.log(res.data);
                //         if (res.data?.msg == 'Dispute created') {
                //             notify("Dispute created", toast.success);
                //             getTradeData()

                //         } else if (typeof res.data.error != "undefined") {
                //             notify(res.data.error, toast.success);
                //             getTradeData()

                //         } else {
                //             notify(res.data, toast.error)

                //         }
                //     })
                //     .catch(err => {
                //         console.log(err);
                //         if (err.response.status == 401) {
                //             sessionStorage.clear();
                //             notify("Unauthorized user or session expired", toast.error)
                //             navigate("/")
                //         }
                //     })

            }

            async function updateInDB(Status1) {
                // await axios.post(`${URL}/user/update-trade-status/${thisTrade._id}/${authData?._id}`, {
                //     status: Status1
                // }, config)
                //     .then(res => {
                //         if (res.data == 'Status updated') {
                //             notify("Status updated", toast.success);
                //             getTradeData()

                //         } else {
                //             notify(res.data, toast.error)

                //         }
                //     })
                //     .catch(err => {
                //         console.log(err);
                //         if (err.response.status == 401) {
                //             sessionStorage.clear();
                //             notify("Unauthorized user or session expired", toast.error)
                //             navigate("/")
                //         }
                //     })

            }
            console.log("==========================================");
        } catch (error) {
            console.log(error);
            notify("System error", toast.error)
            setShowLoader(false)
        }
    }


    useEffect(() => {
        console.log("------- 1st Start -------");

        if (authData?.isConnected == false || authData?.email == "" || authData?.username == "" || authData?.isVerified == "") {
            navigate("/")
        }

        getTradeData()

        console.log("------- 1st End -------");
        // getChatDiv_height()
    }, [])

    // const getChatDiv_height = () => {
    //     var messageBody = document.querySelector('.chatDiv');
    //     document.querySelector(".chatDiv").addEventListener("scroll", myFunction);

    //     async function myFunction() {
    //         if (Math.abs(messageBody.scrollTop) == 0) {
    //             console.log("=-=-=-=-=-=-=", myCurrentChat);
    //             await axios.post(`${URL}/chat/fetch-msg`, {
    //                 "roomID": myCurrentChat._id,
    //                 "offset": msgOffset
    //             })
    //                 .then(res => {
    //                     console.log("messageList");
    //                     console.log(messageList);
    //                     console.log("res.data");
    //                     console.log("res.data");
    //                     console.log(res.data.msgList);
    //                     if (res.data.msgList) {
    //                         // dispatch(currentChat(res.data.chatData))

    //                         const strArr1 = res?.data?.msgList;
    //                         const descArr = strArr1.sort().reverse();

    //                         setMessageList((list) => [...(descArr), ...list]);
    //                         msgOffset += (res?.data?.msgList).length

    //                         // var messageBody = document.querySelector('.chatDiv');
    //                         // messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    //                     }
    //                     res.data.error && alert(res.data.error)

    //                 })
    //                 .catch(err => {
    //                     console.log(err);
    //                 })

    //         }

    //     }
    // }


    // useEffect(() => {
    //     socket.on("receive_message", (data) => {
    //         console.log("receive_message");
    //         console.log(data);
    //         setMessageList((list) => [...list, data]);

    //         var messageBody = document.querySelector('.chatDiv');
    //         messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    //     });

    //     socket.on("receive_img", (data) => {
    //         console.log("receive_img");
    //         console.log(data);
    //         setMessageList((list) => [...list, ...data]);

    //         var messageBody = document.querySelector('.chatDiv');
    //         messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    //     })
    //     // window.scrollTo(0, 0)

    // }, [])



    // useEffect(() => {

    //     if (Object.keys(myCurrentChat).length > 0) {
    //         !socket.connected && socket.connect();

    //         console.log("------- 2nd Start ------- myCurrentChat ", myCurrentChat);
    //         socket.emit("join_room", {
    //             roomID: myCurrentChat._id,
    //             username: authData?.username
    //         });
    //     }

    //     /** ============================  **/


    //     socket.on("onJoinEvent", ({ roomID, joinedUsers }) => {
    //         console.log("on join event");
    //         //   console.log(joinedUsers);
    //         //   console.log(roomID);

    //         joinedUsers.find(data => {
    //             if (data.username == authData?.username) {
    //                 //   console.log(true) 
    //                 setUserIsOnline(true)
    //             }
    //         })
    //         joinedUsers.find(data => {
    //             if (data.username == myFriendName) {
    //                 //   console.log(true) 
    //                 setFriendIsOnline(true)
    //             }
    //         })

    //     });

    //     socket.on("user_disconnect", (data) => {
    //         console.log("user_disconnect");
    //         console.log(data.data);

    //         const userDisconnect = data.data

    //         if (userDisconnect?.username == myFriendName) {
    //             console.log(true)
    //             setFriendIsOnline(false)
    //         }

    //     })


    //     console.log("------- 2nd End -------");

    // }, [myCurrentChat, socket]);

    useEffect(() => {

        // var messageBody = document.querySelector('.chatDiv');
        // messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;

    }, [messageList, showFileProgressBar]);


    function timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        // var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ', ' + hour + ':' + min;
        return time;
    }
    function dateConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = date + ', ' + month + ' ' + year;
        return time;
    }

    async function resolveDispute(winnerID, winnerName, winnerWallet) {
        try {
            console.log("authData", authData);
            const Confirm = window.confirm("Have you decided " + winnerName + " as a winner of this dispute?")
            if (Confirm) {

                // ETH & USDT PART
                if ((thisTrade.currency == "ETH" || thisTrade.currency == "USDT") && thisTrade?.status == "dispute") {
                    setShowLoader(true)

                    const response = await handleDisputeETH(thisTrade?.from?.wallet, thisTrade?.to?.wallet, winnerWallet, thisTrade?.chainID, thisTrade.currency, winnerName, thisTrade?.tradeID)
                    if (response == true) {
                        updateInDB(winnerID, winnerName, winnerWallet)
                        setShowLoader(false)
                    }
                    else {
                        setShowLoader(false)
                    }
                }

                // BNB & BTCB PART
                if ((thisTrade.currency == "BNB" || thisTrade.currency == "BTCB" || thisTrade.currency == "BUSD") && thisTrade?.status == "dispute") {
                    setShowLoader(true)
                    // const response = await startTrade_ETH(_buyer, _seller, winner, _tradeId)
                    const response = await handleDisputeBNB(thisTrade?.from?.wallet, thisTrade?.to?.wallet, winnerWallet, thisTrade?.chainID, thisTrade.currency, winnerName, thisTrade?.tradeID)
                    if (response == true) {
                        updateInDB(winnerID, winnerName, winnerWallet)
                        setShowLoader(false)
                    }
                    else {
                        setShowLoader(false)
                    }
                }

                // MATIC PART
                if ((thisTrade.currency == "MATIC" || thisTrade.currency == "ESCW") && thisTrade?.status == "dispute") {
                    setShowLoader(true)
                    // const response = await startTrade_ETH(_buyer, _seller, winner, _tradeId)
                    const response = await handleDisputeMATIC(thisTrade?.from?.wallet, thisTrade?.to?.wallet, winnerWallet, thisTrade?.chainID, thisTrade.currency, winnerName, thisTrade?.tradeID)
                    if (response == true) {
                        updateInDB(winnerID, winnerName, winnerWallet)
                        setShowLoader(false)
                    }
                    else {
                        setShowLoader(false)
                    }
                }

                async function updateInDB(winnerID, winnerName, winnerWallet) {
                    const config = {
                        headers: { Authorization: `Bearer ${token}` }
                    };
                    await axios.post(`${URL}/user/resolve-dispute/${thisTrade._id}/${authData?._id}`, {
                        winnerName: winnerName,
                        winnerID: winnerID,
                        timestamp: new Date().toUTCString(),
                    },
                        config
                    )
                        .then(res => {
                            console.log((res?.data).hasOwnProperty("error"));
                            if ((res?.data).hasOwnProperty("error")) {
                                notify(res.data.error, toast.error)
                            }
                            if ((res?.data).hasOwnProperty("msg")) {
                                notify(res.data.msg, toast.success)
                                getTradeData()
                            }
                        })
                }
            }

        } catch (error) {
            console.log(error);
            notify(error, toast.error)
        }
    }




    return (
        <>
            <div className={darkMode ? 'darkMode tradeDetailSec' : 'tradeDetailSec'} >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mb-30">
                            <div className="detailCard">
                                <h1 >Details</h1>
                                {/* <div className='tradeDetail'>
                                    <DetailMessage />
                                </div> */}
                                <table>
                                    <tr>
                                        <th className='w-mc dib'> Trade ID</th>
                                        <td>{thisTrade?.tradeID}</td>
                                    </tr>
                                    <tr>
                                        <th className='w-mc dib'> Status</th>
                                        <td><div className={`status ${thisTrade.status ? thisTrade?.status.toLowerCase() : "request"}`} >{thisTrade?.status == "accept" ? "accepted" : thisTrade?.status == "deposit" ? "deposited" : thisTrade?.status == "complete" ? "completed" : thisTrade?.status}</div></td>
                                    </tr>
                                    <tr>
                                        <th className='w-mc dib'> Amount</th>
                                        <td>{parseInt(thisTrade?.crpytoAmount) / 1000000000000000000} {thisTrade?.currency}</td>
                                    </tr>
                                    <tr>
                                        <th className='w-mc dib'> Network</th>
                                        <td>{thisTrade?.network == "binance" ? ("Binance Smart Chain")?.toUpperCase() : (thisTrade?.network)?.toUpperCase()}</td>
                                    </tr>
                                    <tr>
                                        <th className='w-mc dib'> Buyer Username</th>
                                        <td>{thisTrade?.from?.username}</td>
                                    </tr>
                                    <tr>
                                        <th className='w-mc dib'> Buyer Wallet</th>
                                        <td><div className='break-all'> {(thisTrade?.from?.wallet)}</div></td>
                                    </tr>
                                    <tr>
                                        <th className='w-mc dib'> Seller Username</th>
                                        <td>{thisTrade?.to?.username}</td>
                                    </tr>
                                    <tr>
                                        <th className='w-mc dib'> Seller Wallet</th>
                                        <td><div className='break-all'> {(thisTrade?.to?.wallet)}</div></td>
                                    </tr>
                                    {/* <tr>
                                        <th className='w-mc dib'> Contract Address</th>
                                        <td><div className='break-all'> {(thisTrade?.to?.wallet)}</div></td>
                                    </tr> */}
                                </table>
                            </div>
                        </div>

                        <Chat socket={socket} tradeData={thisTrade} />

                        {/* 
                            <div className="col-lg-6 mb-30">
                                <div className="detailCard">
                                    <h1 onClick={() => console.log("myCurrentChat")}>Chat</h1>

                                    <div className="chatDiv">
                                        <div className="chatMain" >
                                            {
                                                messageList?.map((chat, i) => {
                                                    console.log("chat", chat);
                                                    return (
                                                        authData?.username == chat?.author ?
                                                            <div className="sendMsg" key={i}>
                                                                {
                                                                    (chat.type)?.toLowerCase() == "jpeg" || (chat.type)?.toLowerCase() == "jpg" || (chat.type)?.toLowerCase() == "png" || (chat.type)?.toLowerCase() == "gif" ?
                                                                        <div className="sendImg ">

                                                                            <a target='blank' href={`${URL}/fetchChatFiles/${chat?.file}`}>
                                                                                <img src={` ${URL}/fetchChatFiles/${chat?.file} `} width="100px" alt={i} />
                                                                            </a>

                                                                        </div>
                                                                        :
                                                                        (chat.type)?.toLowerCase() == "txt" || (chat.type)?.toLowerCase() == "pdf" || (chat.type)?.toLowerCase() == "doc" || (chat.type)?.toLowerCase() == "docx" || (chat.type)?.toLowerCase() == "json" ?
                                                                            <>
                                                                                <div className="sendImg ">
                                                                                    <a target='blank' href={`${URL}/fetchChatFiles/${chat?.file}`}>
                                                                                        <img src={fileLogo} width="100px" alt={i} />
                                                                                    </a>
                                                                                    <span style={{ fontSize: "x-small", lineHeight: "12px", wordBreak: "break-all" }}>{typeof chat?.originalname != "undefined" && chat?.originalname}</span>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <p className="mb-0">{chat.msg}</p>
                                                                }
                                                                <div className="time">{chat.author}, {moment(chat.timestamp).fromNow()}</div>
                                                            </div>
                                                            :
                                                            <div className="revieveMsg " key={i}>
                                                                {

                                                                    (chat.type)?.toLowerCase() == "jpeg" || (chat.type)?.toLowerCase() == "jpg" || (chat.type)?.toLowerCase() == "png" || (chat.type)?.toLowerCase() == "gif" ?
                                                                        <div className="receiveImg ">

                                                                            <a target='blank' href={`${URL}/fetchChatFiles/${chat?.file}`}>
                                                                                <img src={` ${URL}/fetchChatFiles/${chat?.file} `} width="100px" alt={i} />
                                                                            </a>

                                                                        </div>
                                                                        :
                                                                        (chat.type)?.toLowerCase() == "txt" || (chat.type)?.toLowerCase() == "pdf" || (chat.type)?.toLowerCase() == "doc" || (chat.type)?.toLowerCase() == "docx" || (chat.type)?.toLowerCase() == "json" ?
                                                                            <>
                                                                                <div className="receiveImg">
                                                                                    <a target='blank' href={`${URL}/fetchChatFiles/${chat?.file}`}>
                                                                                        <img src={fileLogo} className="receiveFile" width="100px" alt={i} />
                                                                                    </a>
                                                                                    <span style={{ fontSize: "x-small", lineHeight: "12px", wordBreak: "break-all" }}>{typeof chat?.originalname != "undefined" && chat?.originalname}</span>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <p className="mb-0">{chat.msg}</p>
                                                                }
                                                                <div className="time">{chat.author}, {moment(chat.timestamp).fromNow()}</div>
                                                            </div>
                                                    )
                                                })
                                            }
                                            {
                                                showFileProgressBar == true && <div className='m-1'> <ProgressBar striped variant="success" now={fileUplaodProgress} /> </div>
                                            }
                                            <div ref={bottomRef} > </div>
                                        </div>
                                    </div>

                                </div>
                            </div> 
                        */}


                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="tradeDescription">
                                <div className="detailCard">
                                    <h1>Trade Description</h1>
                                        {
                                            thisTrade.directOffer == "direct" ?
                                                <div className='textarea mb-2' >
                                                    {thisTrade.description}
                                                </div>
                                                :
                                                <div className='textarea mb-2' dangerouslySetInnerHTML={{
                                                    __html: `
                                                        <span style="display: block">${thisTrade?.from?.username} buys ${thisTrade?.quantity}x the following ${thisTrade?.productType} from ${thisTrade?.to?.username} for ${thisTrade?.crpytoAmount / 1000000000000000000} ${thisTrade?.currency} . The ${(thisTrade?.productType == "physical-product" || thisTrade?.productType == "digital-product") ? "product" : "service"} has to be delivered unitil ${thisTrade?.offer?.deliveryTime} days</span>
                                                        <span style="display: block">Bought ${(thisTrade?.productType == "physical-product" || thisTrade?.productType == "digital-product") ? "product" : "service"}</span>
                                                        <span style="display: block">${thisTrade?.offer?.title}</span>
                                                        <span style="display: block">Marketplace offer : ${thisTrade?.offer?._id}</span>
                                                        <span style="display: block">${thisTrade?.offer?.description}</span>
                                                    `
                                                }} />
                                        }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="detailCard mb-0">
                                <h1>History Logs</h1>
                                <div className="historyTable table-responsive">
                                    <table className="table">
                                        <tbody>
                                            {
                                                (thisTrade?.history)?.length > 0 && thisTrade?.history.map((history, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td style={{ border: "none" }}><div className='w-mc'>{(history.timestamp)}</div></td>
                                                            <th scope="row">{history?.msg}</th>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {/* <tr>
                                                <td><div className='w-mc'>18. July 2022, 16:42 UTC</div></td>
                                                <th scope="row">xUser1x created the trade as a buyer and requested _User2_ as a seller.</th>
                                            </tr>
                                            <tr>
                                                <td><div className='w-mc'>18. July 2022, 16:42 UTC</div></td>
                                                <th scope="row">xUser1x created the trade as a buyer and requested _User2_ as a seller.</th>
                                            </tr>
                                            <tr>
                                                <td><div className='w-mc'>18. July 2022, 16:42 UTC</div></td>
                                                <th scope="row">xUser1x created the trade as a buyer and requested _User2_ as a seller.</th>
                                            </tr>
                                            <tr>
                                                <td><div className='w-mc'>18. July 2022, 16:42 UTC</div></td>
                                                <th scope="row">xUser1x created the trade as a buyer and requested _User2_ as a seller.</th>
                                            </tr> */}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                    {
                        thisTrade?.status == "dispute" &&
                        <div className="ActionMain">
                            <div className="detailCard">
                                <h1>Dispute Actions</h1>
                                <div className="btnDiv">
                                    <button className='btn AcceptBtn' onClick={() => resolveDispute(thisTrade?.from?._id, thisTrade?.from?.username, thisTrade?.from?.wallet)}>Buyer ({thisTrade?.from?.username}) wins</button>
                                    <button className='btn DeclineBtn' onClick={() => resolveDispute(thisTrade?.to?._id, thisTrade?.to?.username, thisTrade?.to?.wallet)}>Seller ({thisTrade?.to?.username}) wins</button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-lg-6 my-3">
                            <div className="partnersProfileMain">
                                <div className="detailCard">
                                    <h1>Buyer Profile</h1>
                                    <div className="ProfileMain">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="detailMain">
                                                    <h3 className='name'>{thisTrade?.from?.username}
                                                        <span>
                                                            {thisTrade?.from?.kycStatus == "verified" && <img className='mb-1' src={verified} alt="verified" />}
                                                        </span>
                                                        <span style={{ color: thisTrade?.from?.kycStatus == "not-verified" ? "red" : thisTrade?.from?.kycStatus == "pending" ? "#fad16b" : "#10A549" }}>
                                                            ( {(thisTrade?.from?.kycStatus)?.toUpperCase()} )
                                                        </span>

                                                        {/* <span><img className='mb-1' src={verified} alt="verified" />(Verified)</span> */}
                                                    </h3>
                                                    <p className='address'>{thisTrade?.from?.country != "" && thisTrade?.from?.country != null && typeof thisTrade?.from?.country != "undefined" ? (`From ${thisTrade?.from?.country} - `) : ""} Registered since {dateConverter(thisTrade?.from?.timestamp)}</p>
                                                    <p className='desc'>{typeof thisTrade?.from?.aboutYou != "undefined" && thisTrade?.from?.aboutYou}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="totalTrade">
                                                    <div className="content">
                                                        <p>Total Trades</p>
                                                        <h3>{BuyerTradeCount?.totalTrades}</h3>
                                                    </div>
                                                    <div className="content">
                                                        <p>Completed Trades</p>
                                                        <h3>{BuyerTradeCount?.completeTrades}</h3>
                                                    </div>
                                                    <div className="content">
                                                        <p>Avg. Rating Score</p>
                                                        <h3>{(BuyerTradeCount?.rating).toFixed()}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        asABuyerReviews?.length > 0 &&
                                        <>
                                            <div className="reviewsMain">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h3>Reviews as Buyer</h3>
                                                        {
                                                            asABuyerReviews?.length > 0 && asABuyerReviews?.map((review, i) => {
                                                                return (
                                                                    <div className='review' key={i}>
                                                                        <div className='profile'><span>{((thisTrade?.from?.username).substring(0, 1)).toUpperCase()}</span></div>
                                                                        <div className="content">
                                                                            <div className="name">
                                                                                <p>{review?.user?.username}</p>
                                                                                <span><img src={star} alt="rating" />{review?.stars}</span>
                                                                            </div>
                                                                            <div className="country">{review?.user?.country}</div>
                                                                            <p className='comment'>{review?.text}</p>
                                                                            <p className='publish'>{moment((review?.timestamp)).fromNow()}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 my-3">
                            <div className="partnersProfileMain">
                                <div className="detailCard">
                                    <h1>Seller Profile</h1>
                                    <div className="ProfileMain">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="detailMain">
                                                    <h3 className='name'>{thisTrade?.to?.username}
                                                        <span>
                                                            {thisTrade?.to?.kycStatus == "verified" && <img className='mb-1' src={verified} alt="verified" />}
                                                        </span>
                                                        <span style={{ color: thisTrade?.to?.kycStatus == "not-verified" ? "red" : thisTrade?.to?.kycStatus == "pending" ? "#fad16b" : "#10A549" }}>
                                                            ( {(thisTrade?.to?.kycStatus)?.toUpperCase()} )
                                                        </span>
                                                    </h3>
                                                    <p className='address'>{thisTrade?.to?.country != "" && thisTrade?.to?.country != null && typeof thisTrade?.to?.country != "undefined" ? (`From ${thisTrade?.to?.country} - `) : ""}  Registered since {dateConverter(thisTrade?.to?.timestamp)}</p>
                                                    <p className='desc'>{typeof thisTrade?.to?.aboutYou != "undefined" && thisTrade?.to?.aboutYou}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="totalTrade">
                                                    <div className="content">
                                                        <p>Total Trades</p>
                                                        <h3>{SellerTradeCount?.totalTrades}</h3>
                                                    </div>
                                                    <div className="content">
                                                        <p>Completed Trades</p>
                                                        <h3>{SellerTradeCount?.completeTrades}</h3>
                                                    </div>
                                                    <div className="content">
                                                        <p>Avg. Rating Score</p>
                                                        <h3>{(SellerTradeCount?.rating).toFixed()}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        asASellerReviews?.length > 0 &&
                                        <>
                                            <div className="reviewsMain">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h3>Reviews as Seller</h3>
                                                        {
                                                            asASellerReviews?.length > 0 && asASellerReviews?.map((review, i) => {
                                                                return (
                                                                    <div className='review' key={i}>
                                                                        <div className='profile'><span>{((thisTrade?.from?.username).substring(0, 1)).toUpperCase()}</span></div>
                                                                        <div className="content">
                                                                            <div className="name">
                                                                                <p>{review?.user?.username}</p>
                                                                                <span><img src={star} alt="rating" />{review?.stars}</span>
                                                                            </div>
                                                                            <div className="country">{review?.user?.country}</div>
                                                                            <p className='comment'>{review?.text}</p>
                                                                            <p className='publish'>{moment((review?.timestamp)).fromNow()}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                showLoader &&
                <div className='loader'>
                    <img src={loader} alt="loader" />
                </div>

            }

            <ToastContainer style={{ width: "max-content" }} />

        </>

    );
}

function DetailMessage() {

    return (
        <>
            <p>xXBuyer1337Xx requested xXSellerXx for a trade. It’s xXSellerXx turn to react now.</p>
        </>
    )
}


export { TradeDetail };
