import { useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { AreaChart, Area, XAxis, YAxis, Legend, CartesianGrid, Tooltip, ResponsiveContainer, linearGradient } from 'recharts';
import { PieChart, Pie, Sector, Cell, BarChart, Bar } from 'recharts';
import { URL } from "../config";
import axios from "axios"

var moment = require('moment')


const data = [
    {
        "name": "Page A",
        "pv": 2400
    },
    {
        "name": "Page B",
        "pv": 1398
    },
    {
        "name": "Page C",
        "pv": 9800
    },
    {
        "name": "Page D",
        "pv": 3908
    },
    {
        "name": "Page E",
        "pv": 4800
    },
    {
        "name": "Page F",
        "pv": 3800
    },
    {
        "name": "Page G",
        "pv": 4300
    }
]
const data1 = [
    {
        name: '1',
        uv: 100
    },
    {
        name: '5',
        uv: 80
    },
    {
        name: '10',
        uv: 77
    },
    {
        name: '15',
        uv: 98
    },
    {
        name: '20',
        uv: 45
    },
    {
        name: '25',
        uv: 77
    },
    {
        name: '30',
        uv: 55
    },
];
const map1 = [
    {
        "name": moment(1657494614247).format("DD/MM/YYYY"),
        "uv": 1000000000000000000 / 1000000000000000000
    },
    {
        "name": moment(1657537623878).format("DD/MM/YYYY"),
        "uv": 5000000000000000000 / 1000000000000000000
    },
    {
        "name": moment(1657577685538).format("DD/MM/YYYY"),
        "uv": 1000000000000000000 / 1000000000000000000
    },
    {
        "name": moment(1657748450130).format("DD/MM/YYYY"),
        "uv": 500000000000000000000 / 1000000000000000000
    }
]
const dataAreaChart = [
    {
        "name": "BTCB",
        "value": 9
    },
    {
        "name": "ETH",
        "value": 27
    },
    {
        "name": "BNB",
        "value": 18
    },
    {
        "name": "USDT",
        "value": 9
    },
    {
        "name": "MATIC",
        "value": 36
    },
];

const COLORS = ['#636890', '#229F79', '#f8cf32', '#ED4A9E', '#ff9900', "#9a00f1", "darkgray"];


export const Overveiw = ({ darkMode }) => {
    const navigate = useNavigate();

    const [dateFilter, setDateFilter] = useState({
        startDate: "",
        endDate: ""
    })

    const [TradesCount, setTradesCount] = useState({
        "totalTrades": 0,
        "processingTrades": 0,
        "disputeTrades": 0
    })

    const [grapgData, setGrapgData] = useState([])
    const [CollectedFees, setCollectedFees] = useState([])
    const [UsedCurrencies, setUsedCurrencies] = useState([])

    async function getTradesCount() {
        try {
            await axios.get(`${URL}/admin/count`)
                .then(res => {
                    if (res.data.msg == "done") {
                        setTradesCount({
                            "totalTrades": res.data.totalTrades,
                            "disputeTrades": res.data.disputeTrades,
                            "processingTrades": res.data.processingTrades,
                        })
                    }
                    res.data.error && alert(res.data.error)
                })
                .catch(err => {
                    console.log(err);
                })
        } catch (error) {
            console.log(error);
        }
    }


    const [filter, setFilter] = useState("")
    async function getGraph(dataType) {
        try {
            let StartDay = 0
            let EndDay = 0
            let StartMonth = 0
            let StartYear = 0

            if (dataType == "Today") {
                const a3 = new Date().toUTCString()
                const a2 = moment(a3);
                StartDay = a2.format('D');
                EndDay = 0;
                StartMonth = moment().month() + 1
                StartYear = moment().year()

            } else if (dataType == "Yesterday") {
                const a3 = new Date().toUTCString()
                const a2 = moment(a3);
                StartDay = parseInt(a2.format('D')) - 1
                EndDay = 0;
                StartMonth = moment().month() + 1
                StartYear = moment().year()

            } else if (dataType == "CurrentWeek") {
                const currentDate = moment();
                const a2 = currentDate.clone().startOf('isoWeek');
                const a3 = currentDate.clone().endOf('isoWeek');
                StartDay = a2.format('DD');
                EndDay = a3.format('DD');
                StartMonth = moment().month() + 1
                StartYear = moment().year()

            } else if (dataType == "CurrentMonth") {
                StartDay = 0;
                EndDay = 0;
                StartMonth = moment().month() + 1
                StartYear = moment().year()

            } else if (dataType == "CurrentYear") {
                StartDay = 0;
                EndDay = 0;
                StartMonth = 0
                StartYear = moment().year()
            }
            console.log(StartDay, EndDay);

            await axios.get(`${URL}/admin/graph?StartDay=${StartDay}&EndDay=${EndDay}&dataType=${dataType}&StartMonth=${StartMonth}&StartYear=${StartYear}`)
                .then(res => {
                    if (res.data.msg == "done") {
                        console.log(res.data);

                        if (dataType == "Today") {
                            let ourData = [];
                            (res.data.totalTrades).forEach((loopElement, i) => {
                                ourData.push({
                                    date: `Trade #${i}`,
                                    count: `${parseFloat(loopElement.crpytoAmount) / 1000000000000000000}`
                                });
                            });
                            console.log("ourData => ", ourData);
                            setGrapgData(ourData)

                        } else if (dataType == "Yesterday") {
                            let ourData = [];
                            (res.data.totalTrades).forEach((loopElement, i) => {
                                ourData.push({
                                    date: `Trade #${i}`,
                                    count: `${parseFloat(loopElement.crpytoAmount) / 1000000000000000000}`
                                });
                            });
                            console.log("ourData => ", ourData);
                            setGrapgData(ourData)

                        } else if (dataType == "CurrentWeek" || dataType == "CurrentMonth") {
                            let ourData = [];
                            let checked = [];
                            let asd = 0;
                            (res.data.totalTrades).forEach((loopElement, i) => {
                                let temp1 = checked.filter(filterElement => {
                                    return loopElement?.day == filterElement
                                });
                                if (temp1.length == 0) {
                                    checked.push(loopElement?.day)
                                    ourData.push({
                                        date: `${loopElement?.day}-${loopElement?.month}-${loopElement?.year}`,
                                        count: 1
                                    })
                                    asd++;
                                } else {
                                    ourData[asd - 1].count += 1
                                }
                            });
                            console.log("ourData => ", ourData);
                            setGrapgData(ourData)

                        } else if (dataType == "CurrentYear") {
                            let ourData = [];
                            let checked = [];
                            let asd = 0;
                            (res.data.totalTrades).forEach((loopElement, i) => {
                                let temp1 = checked.filter(filterElement => {
                                    return loopElement?.month == filterElement
                                });
                                if (temp1.length == 0) {
                                    checked.push(loopElement?.month)
                                    ourData.push({
                                        date: monthConverter(parseInt(loopElement?.month) + 1),
                                        count: 1
                                    })
                                    asd++;
                                } else {
                                    ourData[asd - 1].count += 1
                                }
                            });

                            console.log("ourData => ", ourData);
                            setGrapgData(ourData)

                        }

                    }
                    res.data.error && alert(res.data.error)
                })
                .catch(err => {
                    console.log(err);
                })
        } catch (error) {
            console.log(error);
        }
    }

    async function getCollectedFees() {
        try {
            await axios.get(`${URL}/admin/collected-fees `)
                .then(res => {
                    if (res.data.msg == 'done') {
                        console.log(res?.data);
                        setCollectedFees(res?.data?.result)
                    }
                    res.data.error && alert(res.data.error)
                })
                .catch(err => {
                    console.log(err);
                })
        } catch (error) {
            console.log(error);
            alert("System error")
        }
    }

    async function getUsedCurrencies() {
        try {
            await axios.get(`${URL}/admin/used-currencies`)
                .then(res => {
                    console.log(res);
                    if (res.data.msg == 'done') {
                        setUsedCurrencies(res?.data?.map1)
                    }
                    res.data.error && alert(res.data.error)
                })
                .catch(err => {
                    console.log(err);
                })
        } catch (error) {
            console.log(error);
            alert("System error")
        }
    }

    const handleDateFilter = (e) => {

        setDateFilter({ ...dateFilter, [e.target.name]: e.target.value })

        if (e.target.name == "startDate") {
            let start = new Date(e.target.value)
            let timestamp = start.getTime();
            console.log(e.target.name);
            console.log(e.target.value);
            console.log("start", timestamp);
        } else if (e.target.name == "endDate") {
            let end = new Date(e.target.value)
            let timestamp = end.getTime();
            console.log(e.target.name);
            console.log(e.target.value);
            console.log("end", timestamp);
        }

    }

    useEffect(() => {
        getTradesCount()
        getCollectedFees()
        getUsedCurrencies()
        getGraph("CurrentWeek")

    }, [])


    function timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp);
        var months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = date + '-' + month + '-' + year;
        return time;
    }
    function monthConverter(MONTH) {
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var month = months[MONTH];
        return month;
    }



    return (
        <section className={darkMode ? "overveiwMain darkMode" : "overveiwMain"} >
            <div>
                <div className="heading_cont">
                    <h1>Overview</h1>
                </div>
                <div className="container">
                    <div className="btn2 mb-4">

                        <div className="dropdown button">
                            <div className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                <button>Date Selector</button>
                            </div>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a className="dropdown-item text-center" href="#" onClick={(e) => { getGraph("Today"); e.preventDefault() }} >  Today</a>
                                <a className="dropdown-item text-center" href="#" onClick={(e) => { getGraph("Yesterday"); e.preventDefault() }} >  Yesterday</a>
                                <a className="dropdown-item text-center" href="#" onClick={(e) => { getGraph("CurrentWeek"); e.preventDefault() }} >  Current Week</a>
                                <a className="dropdown-item text-center" href="#" onClick={(e) => { getGraph("CurrentMonth"); e.preventDefault() }} >  Current Month</a>
                                <a className="dropdown-item text-center" href="#" onClick={(e) => { getGraph("CurrentYear"); e.preventDefault() }} >  Current Year</a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-4">
                            <div className="sub_container">
                                <div className="f_p">
                                    <p>Total Trades</p>
                                </div>
                                <div className="s_p">
                                    <p> {TradesCount?.totalTrades} </p>
                                </div>
                            </div>
                        </div>
                        {/* 2nd  */}
                        <div className="col-md-4 mb-4">
                            <div className="sub_container">
                                <div className="f_p">
                                    <p>Processing Trades</p>
                                </div>
                                <div className="s2_p">
                                    <p> {TradesCount?.processingTrades} </p>
                                </div>
                            </div>
                        </div>
                        {/* 3rd  */}
                        <div className="col-md-4 mb-4">
                            <div className="sub_container">
                                <div className="f_p">
                                    <p>Open Disputes</p>
                                </div>
                                <div className="s3_p">
                                    <p> {TradesCount?.disputeTrades} </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* chart */}
                    <div className="chart_cont">
                        <p className="py-4">Trades Created</p>
                        <div className="areaChart">
                            <ResponsiveContainer width="100%" height="100%">
                                <AreaChart
                                    width={500}
                                    height={400}
                                    data={grapgData}
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid vertical={false} stroke="#ECE9F1" strokeLinecap="3 3" />
                                    <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#253044" stopOpacity={1} />
                                            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.8} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="date"
                                    />
                                    <YAxis domain={[25, 'dataMax ']} />
                                    <Tooltip />
                                    {/* <Area type="monotone" dataKey="uv" stroke="#8884d8"  fill="" /> */}
                                    <Area type="monotone" dataKey="count" stroke="#253044" fillOpacity={1} fill="url(#colorUv)" />

                                </AreaChart>
                            </ResponsiveContainer>
                        </div>

                    </div>

                    <div className="row ">
                        <div className="col-lg-5 col-md-4 col-xs-12 mb-4">
                            <div className="sub_l_cont">
                                <div className="l_p">
                                    <p>Collected Fees</p>
                                </div>
                                <div className="l_sub_p">
                                    <table>
                                        {
                                            CollectedFees?.length > 0 && CollectedFees.map((data, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td><p>{data?.currency}:</p></td>
                                                        <td><p className='collectVal'>{(data?.profit / 1000000000000000000).toFixed(5)}</p></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-8 col-xs-12 mb-4">
                            <div className="sub_l_cont">
                                <div className="l_p">
                                    <p>Used Currencies</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="pieChatValCard">
                                            {
                                                UsedCurrencies.length > 0 && UsedCurrencies.map((data, i) => {
                                                    return (
                                                        <div key={i} className={data?.name?.toLowerCase()}>
                                                            <p><span></span> {data?.name}</p>
                                                            <div className='val'>{data?.value}%</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="currencyChart">
                                            <ResponsiveContainer width="100%" height="100%">
                                                <PieChart width={300} height={300} >
                                                    <Pie
                                                        data={UsedCurrencies}
                                                        cx={100}
                                                        cy={100}
                                                        innerRadius={50}
                                                        outerRadius={100}
                                                        fill="#ff9900"
                                                        paddingAngle={5}
                                                        dataKey="value"
                                                    >
                                                        {data.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                        ))}
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}