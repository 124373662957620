const initialState = true

const Network_REDUCER = (state = initialState, action) => {
    switch (action.type) {
        case "Network":
            return true;

        case "NetworkNo":
            return false;
            
        default:
            return state;
    }
}

export default Network_REDUCER;