import React, { useState, useEffect } from "react";

import ETHLogo from '../images/ETHLogo.png'
import USDTLogo from '../images/USDTLogo.png'
import BNBLogo from '../images/BNBLogo.png'
import BTCLogo from '../images/BTCLogo.png'
import BUSDLogo from '../images/Binance_USD.png'
import MATICLogo from '../images/matic-token.png'
import ESCROWLogo from '../images/favicon.png'

import Web3 from "web3"
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, chatList, currentChat, friendName, emptyFriendName } from '../redux/actions/actions';

// ------------------------------
import jwt from "jwt-decode";
// ==============================

const ETH_ABI = require("../eth/ETH_PAY_ABI.json")
const BNB_ABI = require("../eth/BNB_PAY_ABI.json")
const MATIC_ABI = require("../eth/MATIC_ABI.json")



// const ETH_PAY = "0x87a22D7405f22a077faCFB8eb0A5D95C980a86AB";
const ETH_PAY = "0x361867F4c717361Dd26b6CFCDbF2088e94384A42";
// const TetherToken = "0x185bab30466bc30b34d73829a19e04124dca7bd0";
const TetherToken = "0xab63fbA4Cb48EBC47aE23d5C9Da250f7dfe6e895";

const BNB_PAY = "0xadfe7a6ae68be8e0606329172bd5bb604343b050";
const BTCBToken = "0xae245F5d554DBe21bC29cd185ecbe7f5B667150E";
const BUSDToken = "0x3871E49B0Aa475634Dd0d95A26f130bEA136DDaE";

// const MATIC_PAY = "0x5c9c316F51666d1c8AFC60584891B49D9e02F82e";
const MATIC_PAY = "0x54Da3ADb772e670eB18aB871806DA16F2b10df93";
const EscrowToken = "0x429cb9738AC5C0874804b9aCfe2ddA64d43A695a";


const eth_provider = "https://goerli.infura.io/v3/104e5586c1fe4cde93558f3f58048ced"
const bnb_provider = "https://data-seed-prebsc-1-s1.binance.org:8545/"
const matic_provider = "https://rpc-mumbai.maticvigil.com/"



export const AllWallet = ({ darkMode }) => {
    const [ETH_Fee, setETH_Fee] = useState("")
    const [BNB_Fee, setBNB_Fee] = useState("")
    const [USDT_Fee, setUSDT_Fee] = useState("")
    const [BTCB_Fee, setBTCB_Fee] = useState("")
    const [BUSD_Fee, setBUSD_Fee] = useState("")
    const [MATIC_Fee, setMATIC_Fee] = useState("")
    const [ESCROWTOKEN_Fee, setESCROWTOKEN_Fee] = useState("")

    const [ETH_Wallet, setETH_Wallet] = useState("")
    const [BNB_Wallet, setBNB_Wallet] = useState("")
    const [USDT_Wallet, setUSDT_Wallet] = useState("")
    const [BTCB_Wallet, setBTCB_Wallet] = useState("")
    const [BUSD_Wallet, setBUSD_Wallet] = useState("")
    const [MATIC_Wallet, setMATIC_Wallet] = useState("")
    const [ESCROWTOKEN_Wallet, setESCROWTOKEN_Wallet] = useState("")

    const navigate = useNavigate();
    const token = sessionStorage.getItem("token")
    const dispatch = useDispatch();
    const authData = token ? jwt(token)["user"][0] : null

    async function fetchFundWallets() {
        const web3ETH = await new Web3(eth_provider)
        const ethContract = await new web3ETH.eth.Contract(ETH_ABI, ETH_PAY)
        // ETH AND USDT FUND WALLET
        await ethContract.methods.fundWallet().call()
            .then(data => {
                console.log(data);
                setETH_Wallet(data)
                setUSDT_Wallet(data)
            })
            .catch(err => console.log(err))

        // ETH FEE AMOUNT
        await ethContract.methods.fee().call()
            .then(data => {
                console.log(data);
                setETH_Fee(data/10)
            })
            .catch(err => console.log(err))

        // USDT FEE AMOUNT
        await ethContract.methods.feeUSDT().call()
            .then(data => {
                console.log(data);
                setUSDT_Fee(data/10)
            })
            .catch(err => console.log(err))

        const web3BNB = await new Web3(bnb_provider)
        const bnbContract = await new web3BNB.eth.Contract(BNB_ABI, BNB_PAY)

        // BNB AND BTCB AND BUSD FUND WALLET
        await bnbContract.methods.fundWallet().call()
            .then(data => {
                console.log(data);
                setBNB_Wallet(data)
                setBTCB_Wallet(data)
                setBUSD_Wallet(data)
            })
            .catch(err => console.log(err))

        // BNB FEE AMOUNT
        await bnbContract.methods.fee().call()
            .then(data => {
                console.log(data);
                setBNB_Fee(data/10)
            })
            .catch(err => console.log(err))

        // BTCB FEE AMOUNT
        await bnbContract.methods.fee().call()
            .then(data => {
                console.log(data);
                setBTCB_Fee(data/10)
            })
            .catch(err => console.log(err))

        // BUSD FEE AMOUNT
        await bnbContract.methods.feeBUSD().call()
            .then(data => {
                console.log(data);
                setBUSD_Fee(data/10)
            })
            .catch(err => console.log(err))

        // MATIC FUND WALLET
        const web3MATIC = await new Web3(matic_provider)
        const maticContract = await new web3MATIC.eth.Contract(MATIC_ABI, MATIC_PAY)
        await maticContract.methods.fundWallet().call()
            .then(data => {
                console.log(data);
                setMATIC_Wallet(data)
                setESCROWTOKEN_Wallet(data)
            })
            .catch(err => console.log(err))

        // MATIC FEE AMOUNT
        await maticContract.methods.fee().call()
            .then(data => {
                console.log(data);
                setMATIC_Fee(data/10)
            })
            .catch(err => console.log(err))

        // ESCROW TOKEN FEE AMOUNT
        await maticContract.methods.feeESCW().call()
            .then(data => {
                console.log(data);
                setESCROWTOKEN_Fee(data/10)
            })
            .catch(err => console.log(err))

    }

    const updateChainData = async (network, coin, action, value) => {
        if (value != "") {
            if (window.ethereum) {
                const web3 = await new Web3(window.ethereum)
                const chainId = await window.ethereum.request({ method: 'eth_chainId' });
                console.log("chainId", chainId);
                if (chainId !== "0x5" && chainId != "0x61" && chainId != "0x13881") {
                    alert("Connect to Correct Network")

                } else {
                    await window.ethereum.enable()
                        .then(async account1 => {
                            console.log(account1, network, coin, action, value);

                            if (network == "ETH" && coin == "ETH" && chainId == "0x3") {
                                const myContract = await new web3.eth.Contract(ETH_ABI, ETH_PAY)
                                if (action == "fee") {
                                    myContract.methods.updateFee(value).send({ from: account1[0] })
                                        .then(data => {
                                            console.log(data);
                                            alert("Fee updated");
                                        })
                                }
                                if (action == "fundwallet") {
                                    myContract.methods.changeFundAddress(value).send({ from: account1[0] })
                                        .then(data => {
                                            console.log(data);
                                            alert("Fund Address updated");
                                        })
                                }

                            }
                            else if (network == "ETH" && coin == "USDT" && chainId == "0x3") {
                                const myContract = await new web3.eth.Contract(ETH_ABI, ETH_PAY)
                                if (action == "fee") {
                                    myContract.methods.updateFeeUSDT(value).send({ from: account1[0] })
                                        .then(data => {
                                            console.log(data);
                                            alert("Fee updated");
                                        })
                                }
                                if (action == "fundwallet") {
                                    myContract.methods.changeFundAddress(value).send({ from: account1[0] })
                                        .then(data => {
                                            console.log(data);
                                            alert("Fund Address updated");
                                        })
                                }

                            }
                            else if (network == "BNB" && coin == "BNB" && chainId == "0x61") {
                                const myContract = await new web3.eth.Contract(BNB_ABI, BNB_PAY)
                                if (action == "fee") {
                                    myContract.methods.updateFee(value).send({ from: account1[0] })
                                        .then(data => {
                                            console.log(data);
                                            alert("Fee updated");
                                        })
                                }
                                if (action == "fundwallet") {
                                    myContract.methods.changeFundAddress(value).send({ from: account1[0] })
                                        .then(data => {
                                            console.log(data);
                                            alert("Fund Address updated");
                                        })
                                }

                            }
                            else if (network == "BNB" && coin == "BTCB" && chainId == "0x61") {
                                const myContract = await new web3.eth.Contract(BNB_ABI, BNB_PAY)
                                if (action == "fee") {
                                    myContract.methods.updateFeeBTCB(value).send({ from: account1[0] })
                                        .then(data => {
                                            console.log(data);
                                            alert("Fee updated");
                                        })
                                }
                                if (action == "fundwallet") {
                                    myContract.methods.changeFundAddress(value).send({ from: account1[0] })
                                        .then(data => {
                                            console.log(data);
                                            alert("Fund Address updated");
                                        })
                                }
                            }
                            else if (network == "BNB" && coin == "BUSD" && chainId == "0x61") {
                                const myContract = await new web3.eth.Contract(BNB_ABI, BNB_PAY)
                                if (action == "fee") {
                                    myContract.methods.updateFeeBUSD(value).send({ from: account1[0] })
                                        .then(data => {
                                            console.log(data);
                                            alert("Fee updated");
                                        })
                                }
                                if (action == "fundwallet") {
                                    myContract.methods.changeFundAddress(value).send({ from: account1[0] })
                                        .then(data => {
                                            console.log(data);
                                            alert("Fund Address updated");
                                        })
                                }
                            }
                            else if (network == "MATIC" && coin == "MATIC" && chainId == "0x13881") {
                                const myContract = await new web3.eth.Contract(MATIC_ABI, MATIC_PAY)
                                if (action == "fee") {
                                    myContract.methods.updateFee(value).send({ from: account1[0] })
                                        .then(data => {
                                            console.log(data);
                                            alert("Fee updated");
                                        })
                                }
                                if (action == "fundwallet") {
                                    myContract.methods.changeFundAddress(value).send({ from: account1[0] })
                                        .then(data => {
                                            console.log(data);
                                            alert("Fund Address updated");
                                        })
                                }
                            
                            } else if (network == "MATIC" && coin == "ESCROW" && chainId == "0x13881") {
                                const myContract = await new web3.eth.Contract(MATIC_ABI, MATIC_PAY)
                                if (action == "fee") {
                                    myContract.methods.updateFee(value).send({ from: account1[0] })
                                        .then(data => {
                                            console.log(data);
                                            alert("Fee updated");
                                        })
                                }
                                if (action == "fundwallet") {
                                    myContract.methods.changeFundAddress(value).send({ from: account1[0] })
                                        .then(data => {
                                            console.log(data);
                                            alert("Fund Address updated");
                                        })
                                }

                            } else {
                                alert(`Please select ${network} network.`)
                            }

                        })
                        .catch((err) => {
                            if (err.code === 4001) {
                                alert("User rejected the request")

                            } else if (err.code === -32602) {
                                alert("Please connect your registered wallet address or reload page")

                            } else {
                                alert(err.message.split(":")[0])

                            }
                        });

                }

            } else {
                alert("Install Metamask Wallet to continue")
            }

        } else {
            alert("Please fill the field");
        }
    }

    useEffect(() => {
        if (!token || typeof token == "undefined") {
            navigate("/")
        }
        fetchFundWallets()
    }, [])



    return (
        <section className={darkMode ? "allWalletMain darkMode" : "allWalletMain"} >
            <div className="container">
                <div className="row">

                    {/* 1st  */}
                    <div className="col-md-6 mb-4">
                        <div className="submain">
                            <div className="firstcont">
                                <div className="heading"><p>ETH</p></div>
                                <div className="logo2">
                                    <img src={ETHLogo} alt="" />
                                </div>
                            </div>
                            <div className="lable">
                                <label>Ethereum Fee</label>
                            </div>
                            <div className="firstcont">
                                <div className="input">
                                    <input placeholder="Enter percentage in whole numbers" type="number" value={ETH_Fee} onChange={(e) => setETH_Fee(e.target.value)} />
                                    <span>%</span>
                                </div>
                                <div className="btnc">
                                    <button onClick={() => updateChainData("ETH", "ETH", "fee", (ETH_Fee * 10))}>Change</button>
                                </div>
                            </div>
                            <div className="lable">
                                <label>ETH Service Fee Wallet</label>
                            </div>
                            <div className="firstcont">
                                <div className="input">
                                    <input placeholder="0x0000000000000000000000000000000000000000" style={{ fontSize: "small" }} type="text" value={ETH_Wallet} onChange={(e) => setETH_Wallet(e.target.value)} />
                                </div>
                                <div className="btnc">
                                    <button onClick={() => updateChainData("ETH", "ETH", "fundwallet", (ETH_Wallet))}>Change</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 2nd  */}
                    <div className="col-md-6 mb-4">
                        <div className="submain">
                            <div className="firstcont">
                                <div className="heading"><p>BNB</p></div>
                                <div className="logo2">
                                    <img src={BNBLogo} alt="" />
                                </div>
                            </div>
                            <div className="lable">
                                <label>BNB Fee</label>
                            </div>
                            <div className="firstcont">
                                <div className="input">
                                    <input placeholder="Enter percentage in whole numbers" type="number" value={BNB_Fee} onChange={(e) => setBNB_Fee(e.target.value)} />
                                    <span>%</span>
                                </div>
                                <div className="btnc">
                                    <button onClick={() => updateChainData("BNB", "BNB", "fee", (BNB_Fee * 10))}>Change</button>
                                </div>
                            </div>
                            <div className="lable">
                                <label>BNB Service Fee Wallet</label>
                            </div>
                            <div className="firstcont">
                                <div className="input">
                                    <input placeholder="0x0000000000000000000000000000000000000000" style={{ fontSize: "small" }} type="text" value={BNB_Wallet} onChange={(e) => setBNB_Wallet(e.target.value)} />
                                </div>
                                <div className="btnc">
                                    <button onClick={() => updateChainData("BNB", "BNB", "fundwallet", (BNB_Wallet))}>Change</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 3rd */}
                    <div className="col-md-6 mb-4">
                        <div className="submain">
                            <div className="firstcont">
                                <div className="heading"><p>USDT</p></div>
                                <div className="logo2">
                                    <img src={USDTLogo} alt="" />
                                </div>
                            </div>
                            <div className="lable">
                                <label>USDT Fee</label>
                            </div>
                            <div className="firstcont">
                                <div className="input">
                                    <input placeholder="Enter percentage in whole numbers" type="number" value={USDT_Fee} onChange={(e) => setUSDT_Fee(e.target.value)} />
                                    <span>%</span>
                                </div>
                                <div className="btnc">
                                    <button onClick={() => updateChainData("ETH", "USDT", "fee", (USDT_Fee * 10))}>Change</button>
                                </div>
                            </div>
                            <div className="lable">
                                <label>USDT Service Fee Wallet:</label>
                            </div>
                            <div className="firstcont">
                                <div className="input">
                                    <input placeholder="0x0000000000000000000000000000000000000000" style={{ fontSize: "small" }} type="text" value={USDT_Wallet} onChange={(e) => setUSDT_Wallet(e.target.value)} />
                                </div>
                                <div className="btnc">
                                    <button onClick={() => updateChainData("ETH", "USDT", "fundwallet", (USDT_Wallet))}>Change</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 4th  */}
                    <div className="col-md-6 mb-4">
                        <div className="submain">
                            <div className="firstcont">
                                <div className="heading"><p>BTCB</p></div>
                                <div className="logo2">
                                    <img src={BTCLogo} alt="" />
                                </div>
                            </div>
                            <div className="lable">
                                <label>BTCB Fee</label>
                            </div>
                            <div className="firstcont">
                                <div className="input">
                                    <input placeholder="Enter percentage in whole numbers" type="number" value={BTCB_Fee} onChange={(e) => setBTCB_Fee(e.target.value)} />
                                    <span>%</span>
                                </div>
                                <div className="btnc">
                                    <button onClick={() => updateChainData("BNB", "BTCB", "fee", (BTCB_Fee * 10))}>Change</button>
                                </div>
                            </div>
                            <div className="lable">
                                <label>BTCB Service Fee Wallet</label>
                            </div>
                            <div className="firstcont">
                                <div className="input">
                                    <input placeholder="0x0000000000000000000000000000000000000000" style={{ fontSize: "small" }} type="text" value={BTCB_Wallet} onChange={(e) => setBTCB_Wallet(e.target.value)} />
                                </div>
                                <div className="btnc">
                                    <button onClick={() => updateChainData("BNB", "BTCB", "fundwallet", (BTCB_Wallet))}>Change</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 5th */}
                    <div className="col-md-6 mb-4">
                        <div className="submain">
                            <div className="firstcont">
                                <div className="heading"><p>BUSD</p></div>
                                <div className="logo2">
                                    <img src={BUSDLogo} alt="" />
                                </div>
                            </div>
                            <div className="lable">
                                <label>BUSD Fee</label>
                            </div>
                            <div className="firstcont">
                                <div className="input">
                                    <input placeholder="Enter percentage in whole numbers" type="number" value={BUSD_Fee} onChange={(e) => setBUSD_Fee(e.target.value)} />
                                    <span>%</span>
                                </div>
                                <div className="btnc">
                                    <button onClick={() => updateChainData("BNB", "BUSD", "fee", (BUSD_Fee * 10))}>Change</button>
                                </div>
                            </div>
                            <div className="lable">
                                <label>BUSD Service Fee Wallet</label>
                            </div>
                            <div className="firstcont">
                                <div className="input">
                                    <input placeholder="0x0000000000000000000000000000000000000000" style={{ fontSize: "small" }} type="text" value={BUSD_Wallet} onChange={(e) => setBUSD_Wallet(e.target.value)} />
                                </div>
                                <div className="btnc">
                                    <button onClick={() => updateChainData("BNB", "BUSD", "fundwallet", (BUSD_Wallet))}>Change</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 6th */}
                    <div className="col-md-6 mb-4">
                        <div className="submain">
                            <div className="firstcont">
                                <div className="heading"><p>MATIC</p></div>
                                <div className="logo2">
                                    <img src={MATICLogo} alt="" />
                                </div>
                            </div>
                            <div className="lable">
                                <label>MATIC Fee</label>
                            </div>
                            <div className="firstcont">
                                <div className="input">
                                    <input placeholder="Enter percentage in whole numbers" type="number" value={MATIC_Fee} onChange={(e) => setMATIC_Fee(e.target.value)} />
                                    <span>%</span>
                                </div>
                                <div className="btnc">
                                    <button onClick={() => updateChainData("MATIC", "MATIC", "fee", (MATIC_Fee * 10))}>Change</button>
                                </div>
                            </div>
                            <div className="lable">
                                <label>MATIC Service Fee Wallet</label>
                            </div>
                            <div className="firstcont">
                                <div className="input">
                                    <input placeholder="0x0000000000000000000000000000000000000000" style={{ fontSize: "small" }} type="text" value={MATIC_Wallet} onChange={(e) => setMATIC_Wallet(e.target.value)} />
                                </div>
                                <div className="btnc">
                                    <button onClick={() => updateChainData("MATIC", "MATIC", "fundwallet", (MATIC_Wallet))}>Change</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 7th */}
                    <div className="col-md-6 mb-4">
                        <div className="submain">
                            <div className="firstcont">
                                <div className="heading"><p className="jkh7">ESCW</p></div>
                                <div className="logo2">
                                    <img src={ESCROWLogo} alt="" />
                                </div>
                            </div>
                            <div className="lable">
                                <label>ESCW Fee</label>
                            </div>
                            <div className="firstcont">
                                <div className="input">
                                    <input placeholder="Enter percentage in whole numbers" type="number" value={ESCROWTOKEN_Fee} onChange={(e) => setESCROWTOKEN_Fee(e.target.value)} />
                                    <span>%</span>
                                </div>
                                <div className="btnc">
                                    <button onClick={() => updateChainData("MATIC", "ESCROW", "fee", (ESCROWTOKEN_Fee * 10))}>Change</button>
                                </div>
                            </div>
                            <div className="lable">
                                <label>ESCW Service Fee Wallet</label>
                            </div>
                            <div className="firstcont">
                                <div className="input">
                                    <input placeholder="0x0000000000000000000000000000000000000000" style={{ fontSize: "small" }} type="text" value={MATIC_Wallet} onChange={(e) => setMATIC_Wallet(e.target.value)} />
                                </div>
                                <div className="btnc">
                                    <button onClick={() => updateChainData("MATIC", "ESCROW", "fundwallet", (MATIC_Wallet))}>Change</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}