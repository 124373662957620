const initialState = [];

const CurrentChat_REDUCER = (state = initialState, action) => {
    switch (action.type) {
        case "CurrentChat":
            return action.payload || state;
        
        default:
            return state;
    }
}

export default CurrentChat_REDUCER;