const initialState = []

const Trades_REDUCER = (state = initialState, action) => {
    switch (action.type) {
        case "Trades":
            return  action.payload || state;
            
        case "UpdateTrades":
            return [  action.payload, ...state ]

        default:
            return state;
    }
}

export default Trades_REDUCER;