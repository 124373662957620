const initialState = {
    id: "",
    name: ""
};

const FriendName_REDUCER = (state = initialState, action) => {
    switch (action.type) {
        case "FriendName":
            return "" || action.payload || state;
    
        case "EmptyFriendName":
            return "";
    
        default:
            return state;
    }
}

export default FriendName_REDUCER;