// Here we are saving user data 
export const network = (data) => {
    return{
        type: "Network",
        payload: true
    }
}
export const networkno = (data) => {
    return{
        type: "NetworkNo",
        payload: false
    }
}

// Here we are saving user data 
export const login = (data) => {
    return{
        type: "Login",
        payload: data
    }
}

export const logout = (data) => {
    return{
        type: "Logout",
        payload: {}
    }
}

// Here we are saving user trades data 
export const trades = (data) => {
    return{
        type: "Trades",
        payload: data
    }
}

export const addTrade = (data) => {
    return{
        type: "UpdateTrades",
        payload: data
    }
}

// in this all chats of a user with other users will save
export const chatList = (data) => {
    return{
        type: "ChatList",
        payload: data
    }
}

// Here data of a current chat or chat with someone will save
export const currentChat = (data) => {
    return{
        type: "CurrentChat",
        payload: data
    }
}

// Here friend name will save with whom user want to chat
export const friendName = (data) => {
    return{
        type: "FriendName",
        payload: data
    }
}

// Here friend name will save as empty string
export const emptyFriendName = () => {
    return{
        type: "EmptyFriendName",
        payload: ""
    }
}